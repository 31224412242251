import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  forwardRef,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withHashLocation,
} from '@angular/router';
import {
  AUTHENTICATION_SERVICE_TOKEN,
  LAYOUT_SERVICE_TOKEN as LAYOUT_SERVICE_TOKEN,
  VALIDATION_SERVICE_TOKEN,
  StorageTypeEnum,
  VIEW_MAP_TOKEN,
  TViewItem,
  IUiControlSettings,
  ASSETS_TOKEN,
  NAVIGATION_SERVICE_TOKEN,
  LOADING_SERVICE_TOKEN,
  MODAL_SERVICE_TOKEN,
  MODAL_PERMISSION_TOKEN,
  ENVIRONMENT_TOKEN,
  INPUT_DIRECTIVE_TOKEN,
} from '@aksia/infrastructure';
import {
  ApiService,
  CacheInterceptor,
  CsrfHeaderInterceptor,
  LayoutService,
  MockService,
  ValidationService,
  NavigationService,
  LoadingService,
} from '@aksia/services';
import { environment } from '../app/environments/environment';
import { appRoutes } from '../app/app.routes';
import { AuthenticationService } from '../app/services/authentication.service';
import { AssetTypeEnum } from '@aksia/enums';
import {
  ASSET_TEAM_VIEW_MAP,
  AssetTeamViewType,
  AssetViewMap,
} from '@aksia/models';
import { ModalService, UiInputDirective } from '@aksia/ui';
import { PermissionsContent } from './contents/common/common.content';

const apiService =
  environment.storage_type === StorageTypeEnum.LocalStorage
    ? [{ provide: ApiService, useClass: MockService }]
    : [{ provide: ApiService, useClass: ApiService }];

export const appConfig: ApplicationConfig = {
  providers: [
    ...apiService,
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(HttpClientJsonpModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      appRoutes,
      withHashLocation(),
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
    ),
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment,
    },
    {
      provide: NAVIGATION_SERVICE_TOKEN,
      useExisting: NavigationService,
    },
    {
      provide: LOADING_SERVICE_TOKEN,
      useExisting: LoadingService,
    },
    {
      provide: AUTHENTICATION_SERVICE_TOKEN,
      useExisting: AuthenticationService,
    },
    {
      provide: LAYOUT_SERVICE_TOKEN,
      useExisting: LayoutService<
        AssetTypeEnum,
        AssetTeamViewType,
        TViewItem<IUiControlSettings>,
        AssetViewMap
      >,
    },
    {
      provide: VALIDATION_SERVICE_TOKEN,
      useExisting: ValidationService,
    },
    {
      provide: ASSETS_TOKEN,
      useValue: new Map([
        ['views', { assetPath: '/assets/views/VIEWS.json' }],
        ['items', { assetPath: '/assets/views/ITEMS.json' }],
        ['permissions', { assetPath: '/assets/views/PERMISSIONS.json' }],
        [
          'universal_tags',
          {
            assetPath: '/assets/sections/UNIVERSAL_TAGS.json',
          },
        ],
        [
          'asset_class_tags',
          {
            assetPath: '/assets/sections/ASSET_CLASS_TAGS.json',
          },
        ],
      ]),
    },
    {
      provide: VIEW_MAP_TOKEN,
      useValue: ASSET_TEAM_VIEW_MAP,
    },
    {
      provide: MODAL_SERVICE_TOKEN,
      useExisting: ModalService,
    },
    {
      provide: MODAL_PERMISSION_TOKEN,
      useFactory: () => PermissionsContent,
    },
  ],
};
