@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <dropdown
    class="c1s1"
    [(value)]="iv.isLevered"
    [settings]="{
      label: 'Levered',
      labelTip:
        'Select Yes if the fund employs structural leverage over the life of the fund as a key driver of the risk/return profile. \r\nSelect No if leverage is used only for short-term operational purposes (e.g. sub-lines) or if no structural leverage is present.',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      optionsFiltering: false,
      tag: 'Levered',
    }"
  ></dropdown>
  @for (leverageType of ['Fund', 'Asset']; track i; let i = $index) {
    <section-group
      class="c1s2"
      [settings]="{
        label: '',
      }"
    >
      @let leverageTypeEnum =
        leverageType === 'Fund'
          ? ENUMS.LeverageTypeEnum.Fund
          : ENUMS.LeverageTypeEnum.Asset;
      @let leverageLimit =
        leverageType === 'Fund'
          ? iv.fundLevelLeverageLimit
          : iv.assetLevelLeverageLimit;
      @let leverages =
        leverageTypeEnum === ENUMS.LeverageTypeEnum.Fund
          ? ivStore.fundLevelLeverages
          : ivStore.assetLevelLeverages;

      <dropdown
        class="c1s1"
        [initialValue]="leverageLimit!"
        [settings]="{
          label: leverageType + '-Level Leverage Limit',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          optionsFiltering: false,
          tag: leverageType + '-Level Leverage Limit',
        }"
        [validators]="ivStore.leverageLimit_validators()"
        [validation.id]="iv.$uid!"
        (valueChange)="ivStore.updateLeverageLimit($event, leverageTypeEnum)"
      ></dropdown>
      @for (
        leverage of leverages();
        track leverage.$uid;
        let i = $index, c = $count
      ) {
        <dropdown
          class="c2s1"
          [(value)]="leverage.leverageBasis"
          [settings]="{
            label: ' ',
            options: ENUMS.LeverageBasisEnum.toOptions,
            optionsFiltering: false,
            placeholder: 'calculated on',
            placeholderPersists: true,
            tag: leverageType + ' Leverage Basis #' + (i + 1),
          }"
          [validators]="ivStore.leverageBasis_validators()"
          [validation.id]="iv.$uid! + '_' + leverage.$uid!"
          (valueChange)="
            ivStore.updateLeverage(leverage, 'leverageBasis', $event)
          "
        ></dropdown>
        @if (leverage?.leverageBasis) {
          @if (
            [
              ENUMS.LeverageBasisEnum.leverage_to_value__LTV____,
              ENUMS.LeverageBasisEnum.leverage_to_cost__LTC____,
            ].includes(leverage.leverageBasis!)
          ) {
            <number
              class="c3s1"
              [(value)]="leverage.leveragePercentageMin"
              [settings]="{
                label: 'Minimum',
                placeholder: '%',
                suffix: ' %',
                tag: leverageType + ' Leverage Percentage Min #' + (i + 1),
              }"
              [validators]="ivStore.leveragePercentageMin_validators()"
              [validation.id]="iv.$uid! + '_' + leverage.$uid!"
              (valueChange)="
                ivStore.updateLeverage(
                  leverage,
                  'leveragePercentageMin',
                  $event
                )
              "
            ></number>
          }
          @if (
            [ENUMS.LeverageBasisEnum.calculated_on_other_amount].includes(
              leverage.leverageBasis!
            )
          ) {
            <text
              class="c3s1"
              [(value)]="leverage.leverageDesc"
              [settings]="{
                label: 'Description',
                tag: leverageType + ' Leverage Description #' + (i + 1),
              }"
            ></text>
          }
          @if (
            ![
              ENUMS.LeverageBasisEnum.debt_to_equity__D___E____,
              ENUMS.LeverageBasisEnum.net_debt___EBITDA,
            ].includes(leverage.leverageBasis!)
          ) {
            <number
              class="c4s1"
              [(value)]="leverage.leveragePercentageMax"
              [settings]="{
                label: 'Maximum',
                placeholder: '%',
                suffix: ' %',
                tag: leverageType + ' Leverage Percentage Max #' + (i + 1),
              }"
              [validators]="ivStore.leveragePercentageMax_validators()"
              [validation.id]="iv.$uid! + '_' + leverage.$uid!"
              (valueChange)="
                ivStore.updateLeverage(
                  leverage,
                  'leveragePercentageMax',
                  $event
                )
              "
            ></number>
          }
          @if (
            [
              ENUMS.LeverageBasisEnum.leverage_to_value__LTV____,
              ENUMS.LeverageBasisEnum.leverage_to_cost__LTC____,
            ].includes(leverage.leverageBasis!)
          ) {
            <number
              #leveragePerTargetCtrl
              class="c5s1"
              [(value)]="leverage.leveragePercentageTarget"
              [settings]="{
                label: 'Target',
                placeholder: '%',
                suffix: ' %',
                tag: leverageType + ' Leverage Percentage Target #' + (i + 1),
              }"
              [validators]="ivStore.leveragePercentageTarget_validators()"
              [validation.id]="iv.$uid! + '_' + leverage.$uid!"
              (valueChange)="
                ivStore.updateLeverage(
                  leverage,
                  'leveragePercentageTarget',
                  $event
                )
              "
            ></number>
          }
          @if (
            [
              ENUMS.LeverageBasisEnum.debt_to_equity__D___E____,
              ENUMS.LeverageBasisEnum.net_debt___EBITDA,
            ].includes(leverage.leverageBasis!)
          ) {
            <number
              #leverageMulMinCtrl
              class="c3s1"
              [(value)]="leverage.leverageMultipleMin"
              [settings]="{
                label: 'Minimum',
                placeholder: 'x',
                suffix: 'x',
                tag: leverageType + ' Leverage Multiple Min #' + (i + 1),
              }"
              [validators]="ivStore.leverageMultipleMin_validators()"
              [validation.id]="iv.$uid! + '_' + leverage.$uid!"
              (valueChange)="
                ivStore.updateLeverage(leverage, 'leverageMultipleMin', $event)
              "
            ></number>
          }
          @if (
            [
              ENUMS.LeverageBasisEnum.debt_to_equity__D___E____,
              ENUMS.LeverageBasisEnum.net_debt___EBITDA,
            ].includes(leverage.leverageBasis!)
          ) {
            <number
              #leverageMulMaxCtrl
              class="c4s1"
              [(value)]="leverage.leverageMultipleMax"
              [settings]="{
                label: 'Maximum',
                placeholder: 'x',
                suffix: 'x',
                tag: leverageType + ' Leverage Multiple Max #' + (i + 1),
              }"
              [validators]="ivStore.leverageMultipleMax_validators()"
              [validation.id]="iv.$uid! + '_' + leverage.$uid!"
              (valueChange)="
                ivStore.updateLeverage(leverage, 'leverageMultipleMax', $event)
              "
            ></number>
          }
          @if (
            [
              ENUMS.LeverageBasisEnum.debt_to_equity__D___E____,
              ENUMS.LeverageBasisEnum.net_debt___EBITDA,
            ].includes(leverage.leverageBasis!)
          ) {
            <number
              #leverageMulTargetCtrl
              class="c5s1"
              [(value)]="leverage.leverageMultipleTarget"
              [settings]="{
                label: 'Target',
                placeholder: 'x',
                suffix: 'x',
                tag: leverageType + ' Leverage Multiple Target #' + (i + 1),
              }"
              [validators]="ivStore.leverageMultipleTarget_validators()"
              [validation.id]="iv.$uid! + '_' + leverage.$uid!"
              (valueChange)="
                ivStore.updateLeverage(
                  leverage,
                  'leverageMultipleTarget',
                  $event
                )
              "
            ></number>
          }
        }
        <div class="flex c6s1 place-self-stretch">
          @if (i > 0) {
            <btn
              class="place-self-stretch"
              [settings]="{
                icon: ICONS.remove,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Remove ' + leverageType + ' Leverage #' + (i + 1),
              }"
              (click)="ivStore.removeLeverage(leverage)"
            ></btn>
          }
          @if (i === c - 1) {
            <btn
              class="place-self-stretch"
              [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Add ' + leverageType + ' Leverage',
              }"
              (click)="ivStore.addLeverage(leverageTypeEnum)"
            ></btn>
          }
        </div>
      }
    </section-group>
  }
  <number
    class="c1s1"
    [(value)]="iv.closedEndDetails.bridgeFinancingTerm"
    [settings]="{
      label: 'Bridge Financing Term',
      placeholder: '# of months',
      suffix: ' months',
      tag: 'Bridge Financing Term',
    }"
  ></number>
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.capitalCallLine"
    [settings]="{
      label: 'Capital Call Line',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      optionsFiltering: false,
      tag: 'Capital Call Line',
    }"
  ></dropdown>
  <number
    class="c2s1"
    [(value)]="
      iv.closedEndDetails.closedEndTargetReturnProfile.targetAnnualDivident
    "
    [settings]="{
      label: 'Expected Annual Distribution',
      placeholder: '%',
      suffix: ' %',
      tag: 'Expected Annual Distribution',
    }"
  ></number>
}
