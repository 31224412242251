import { computed, signal, Signal } from '@angular/core';
import { signalStoreFeature, type, withComputed } from '@ngrx/signals';
import {
  CarriedInterestTieredRate,
  ClosedShareClass,
  Discount,
  Enums,
  Gate,
  IncFeeRate,
  InvestmentVehicle,
  Lockup,
  MgmtFeePeriod,
  MgmtFeeRate,
  OpenShareClass,
  Location,
} from '@aksia/models';
import { AksiaValidators, UTILS } from '@aksia/infrastructure';

const calendarFrequencyEqualsValue = (
  value: Enums.CalendarUnitEnum | (() => Enums.CalendarUnitEnum),
) => {
  let plainValue = typeof value === 'function' ? value() : value;
  return computed(() => {
    switch (plainValue) {
      case Enums.CalendarUnitEnum.Semesters:
      case Enums.CalendarUnitEnum.Quarters:
        return 2;
      case Enums.CalendarUnitEnum.Months:
        return 3;
      case Enums.CalendarUnitEnum.Days:
        return 15;
      default:
        return undefined;
    }
  });
};

const calendarFrequencyEqualsMsg = (
  value: Enums.CalendarUnitEnum | (() => Enums.CalendarUnitEnum),
) => {
  let plainValue = typeof value === 'function' ? value() : value;
  return computed<string | undefined>(() => {
    switch (plainValue) {
      case Enums.CalendarUnitEnum.Semesters:
        return 'Did you mean every 1 year?';
      case Enums.CalendarUnitEnum.Quarters:
        return 'Did you mean every 1 semester?';
      case Enums.CalendarUnitEnum.Months:
        return 'Did you mean every 1 quarter';
      case Enums.CalendarUnitEnum.Days:
        return 'Did you mean every 2 weeks';
      default:
        return undefined;
    }
  });
};

const editIndex = signal<number>(0);

export function withShareClassValidators<EntityValidators>() {
  return signalStoreFeature(
    {
      computed: type<{
        investmentVehicle?: Signal<InvestmentVehicle | undefined>;
        selectedOpenClass?: Signal<OpenShareClass | undefined>;
        selectedClosedClass?: Signal<ClosedShareClass | undefined>;
        orderedAddresses?: Signal<Location[] | undefined>;
        filteredLockups?: Signal<Lockup[] | undefined>;
        filteredGates?: Signal<Gate[] | undefined>;
        filteredOpenMgmtFeeRates?: Signal<MgmtFeeRate[] | undefined>;
        filteredOpenMgmtFeeDiscounts?: Signal<Discount[] | undefined>;
        filteredIncFeeRates?: Signal<IncFeeRate[] | undefined>;
        filteredIncFeeDiscounts?: Signal<Discount[] | undefined>;
        filteredMgmtFeePeriods?: Signal<MgmtFeePeriod[] | undefined>;
        filteredClosedMgmtFeeDiscounts?: Signal<Discount[] | undefined>;
        filteredCarriedInterestTieredRates?: Signal<
          CarriedInterestTieredRate[] | undefined
        >;
        filteredCarriedInterestDiscounts?: Signal<Discount[] | undefined>;
      }>(),
    },
    withComputed((store) => ({
      //#region Open Share Class Validators

      hasSidePocket_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      sidePocketLevel_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.notRepeated(
          store
            .selectedOpenClass?.()
            ?.sidePockets?.filter((sidePocket) => !sidePocket.$isDeleted)
            ?.map((sidePocket) => sidePocket.sidePocketLevel as number),
          {
            userMessage: 'Should not be selected more than once.',
          },
        ),
      ]),
      sidePocketLimit_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0),
        AksiaValidators.min(5, { isWarning: true }),
        AksiaValidators.max(30, { isWarning: true }),
        AksiaValidators.max(100),
      ]),
      minimumInitialInvestment_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(
          () => store.selectedOpenClass?.()?.minimumAdditionalInvestment,
        ),
        AksiaValidators.min(100_000, { isWarning: true }),
        AksiaValidators.max(10_000_000, { isWarning: true }),
      ]),
      minimumAdditionalInvestment_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.max(5_000_000, { isWarning: true }),
        AksiaValidators.max(
          () => store.selectedOpenClass?.()?.minimumInitialInvestment,
        ),
      ]),
      minimumInvestmentCurrency_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      hasSubscriptionFee_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      subscriptionFee_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.max(3, { isWarning: true }),
        AksiaValidators.max(100),
      ]),
      hasLockup_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      lockupDurationMinRange_validators: computed(() => (lockup: Lockup) => {
        let i = () => store.filteredLockups?.()?.indexOf(lockup) ?? 0;
        let first = i() === 0;

        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(
            !first
              ? () =>
                  store?.filteredLockups?.()?.at(i() - 1)
                    ?.lockupDurationMaxRange ?? 1
              : 1,
          ),
          AksiaValidators.max(() => lockup.lockupDurationMaxRange),
          AksiaValidators.max(36, { isWarning: true }),
        ];
      }),
      lockupDurationMaxRange_validators: computed(() => (lockup: Lockup) => {
        let i = () => store.filteredLockups?.()?.indexOf(lockup) ?? 0;

        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(() => lockup.lockupDurationMinRange ?? 1),
          AksiaValidators.max(
            () =>
              store?.filteredLockups?.()?.at(i() + 1)?.lockupDurationMinRange!,
          ),
          AksiaValidators.max(36, { isWarning: true }),
        ];
      }),
      lockupType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      lockupFee_validators: computed(() => (lockup: Lockup) => {
        let i = () => store.filteredLockups?.()?.indexOf(lockup) ?? 0;
        let first = i() === 0;

        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(
            () => store.filteredLockups?.()?.at(i() + 1)?.lockupFee ?? 0,
          ),
          AksiaValidators.min(1, { isWarning: true }),
          AksiaValidators.max(
            first
              ? 100
              : () => store.filteredLockups?.()?.at(i() - 1)?.lockupFee ?? 100,
          ),
          AksiaValidators.max(10, { isWarning: true }),
        ];
      }),
      redemptionTermsFrequencyAmount_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(1, { isWarning: true }),
        AksiaValidators.max(
          () =>
            store.selectedOpenClass?.()?.redemptionTermsFrequency ===
            Enums.CalendarUnitEnum.Days
              ? 5
              : 3,
          { isWarning: true },
        ),
        AksiaValidators.userDefined(
          (value: unknown) => {
            let isInvalid =
              value ===
              calendarFrequencyEqualsValue(
                store.selectedOpenClass?.()?.redemptionTermsFrequency!,
              )();
            let message = calendarFrequencyEqualsMsg(
              store.selectedOpenClass?.()?.redemptionTermsFrequency!,
            )();
            return {
              isInvalid,
              message,
            };
          },
          { isWarning: true },
        ),
      ]),
      redemptionTermsFrequency_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      redemptionTermsFrequencyDay_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      redemptionTermsNotice_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.max(120, { isWarning: true }),
      ]),
      redemptionTermsNoticePeriod_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      redemptionFee_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(1, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(10, { isWarning: true }),
      ]),
      hasGate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      gateLevel_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.notRepeated(
          () =>
            store?.filteredGates?.()?.map((gate) => gate.gateLevel as number),
          {
            userMessage: 'Should not be selected more than once.',
          },
        ),
      ]),
      gatePercentage_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(35, { isWarning: true }),
      ]),
      gateMasterFundPercentage_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(35, { isWarning: true }),
      ]),
      gateFundPercentage_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(35, { isWarning: true }),
      ]),
      gateInvestorPercentage_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(35, { isWarning: true }),
      ]),
      holdback_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(2, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(10, { isWarning: true }),
      ]),
      hasManagementFee_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      mgmtFeeRateType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      mgmtFeeRatePercentage_validators: computed(
        () => (mgmtFeeRate: MgmtFeeRate) => {
          let i = () =>
            store.filteredOpenMgmtFeeRates?.()?.indexOf(mgmtFeeRate) ?? 0;
          let first = i() === 0;

          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(
              () =>
                store?.filteredOpenMgmtFeeRates?.()?.at(i() + 1)
                  ?.mgmtFeeRatePercentage ?? 0,
              { includeValue: true },
            ),
            AksiaValidators.max(
              first
                ? 100
                : () =>
                    store?.filteredOpenMgmtFeeRates?.()?.at(i() - 1)
                      ?.mgmtFeeRatePercentage ?? 100,
              { includeValue: true },
            ),
            AksiaValidators.max(3, { isWarning: true }),
          ];
        },
      ),
      mgmtFeeRateAmountFrom_validators: computed(
        () => (mgmtFeeRate: MgmtFeeRate) => {
          let i = () =>
            store.filteredOpenMgmtFeeRates?.()?.indexOf(mgmtFeeRate) ?? 0;
          let first = i() === 0;

          return [
            AksiaValidators.min(0, { includeValue: true }),
            AksiaValidators.min(
              first
                ? 0
                : () =>
                    store?.filteredOpenMgmtFeeRates?.()?.at(i() - 1)
                      ?.mgmtFeeRateAmountTo,
            ),
            AksiaValidators.max(() => mgmtFeeRate.mgmtFeeRateAmountTo),
          ];
        },
      ),
      mgmtFeeRateAmountTo_validators: computed(
        () => (mgmtFeeRate: MgmtFeeRate) => {
          let i = () =>
            store.filteredOpenMgmtFeeRates?.()?.indexOf(mgmtFeeRate) ?? 0;

          return [
            AksiaValidators.min(() => mgmtFeeRate.mgmtFeeRateAmountFrom ?? 0, {
              includeValue: true,
            }),
            AksiaValidators.max(
              () =>
                store?.filteredOpenMgmtFeeRates?.()?.at(i() + 1)
                  ?.mgmtFeeRateAmountFrom,
            ),
          ];
        },
      ),
      payable_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      payableDescription_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      hasManagementFeeDiscounts_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      openMgmtDiscountType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      openMgmtDiscountRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.max(
          () =>
            store.filteredOpenMgmtFeeRates?.()?.at(0)?.mgmtFeeRatePercentage ??
            100,
        ),
      ]),
      openMgmtDiscountDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      hasIncentiveFee_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      incFeeRateType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      incFeeRatePercentage_validators: computed(
        () => (incFeeRate: IncFeeRate) => {
          let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;
          let first = i() === 0;

          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(5, { isWarning: true }),
            AksiaValidators.min(
              first
                ? 0
                : () =>
                    store?.filteredIncFeeRates?.()?.at(i() - 1)
                      ?.incFeeRatePercentage ?? 0,
              { includeValue: true },
            ),
            AksiaValidators.max(30, { isWarning: true }),
            AksiaValidators.max(
              () =>
                store?.filteredIncFeeRates?.()?.at(i() + 1)
                  ?.incFeeRatePercentage ?? 100,
            ),
          ];
        },
      ),
      incFeeMinRatePercentage_validators: computed(
        () => (incFeeRate: IncFeeRate) => {
          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(0, { includeValue: true }),
            AksiaValidators.min(5, { isWarning: true }),
            AksiaValidators.max(100),
            AksiaValidators.max(() => incFeeRate.incFeeMaxRatePercentage, {
              includeValue: true,
            }),
            AksiaValidators.max(30, { isWarning: true }),
          ];
        },
      ),
      incFeeMaxRatePercentage_validators: computed(
        () => (incFeeRate: IncFeeRate) => {
          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(() => incFeeRate.incFeeMinRatePercentage ?? 0, {
              includeValue: true,
            }),
            AksiaValidators.min(5, { isWarning: true }),
            AksiaValidators.max(100),
            AksiaValidators.max(30, { isWarning: true }),
          ];
        },
      ),
      hasHurdle_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      hurdleRateType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      hurdleRate_validators: computed(() => (incFeeRate: IncFeeRate) => {
        let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;
        let first = i() === 0;

        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(2, { isWarning: true }),
          AksiaValidators.min(
            first
              ? 0
              : () =>
                  store?.filteredIncFeeRates?.()?.at(i() - 1)?.hurdleRate ?? 0,
            { includeValue: true },
          ),
          AksiaValidators.max(10, { isWarning: true }),
          AksiaValidators.max(
            () => store?.filteredIncFeeRates?.()?.at(i() + 1)?.hurdleRate,
            { includeValue: true },
          ),
          AksiaValidators.max(100),
        ];
      }),
      hurdleMinRatePercentage_validators: computed(
        () => (incFeeRate: IncFeeRate) => {
          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(2, { isWarning: true }),
            AksiaValidators.min(0, { includeValue: true }),
            AksiaValidators.max(10, { isWarning: true }),
            AksiaValidators.max(100),
            AksiaValidators.max(() => incFeeRate.hurdleMaxRatePercentage, {
              includeValue: true,
            }),
          ];
        },
      ),
      hurdleMaxRatePercentage_validators: computed(
        () => (incFeeRate: IncFeeRate) => {
          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(2, { isWarning: true }),
            AksiaValidators.min(() => incFeeRate.hurdleMinRatePercentage ?? 0, {
              includeValue: true,
            }),
            AksiaValidators.max(20, { isWarning: true }),
            AksiaValidators.max(100),
          ];
        },
      ),
      crystalization_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(1, { isWarning: true }),
        AksiaValidators.max(
          () =>
            store.selectedOpenClass?.()?.crystalizationPeriod ===
            Enums.CalendarUnitEnum.Days
              ? 5
              : 3,
          { isWarning: true },
        ),
        AksiaValidators.userDefined(
          (value: unknown) => {
            let isInvalid =
              value ===
              calendarFrequencyEqualsValue(
                store.selectedOpenClass?.()?.crystalizationPeriod!,
              )();
            let message = calendarFrequencyEqualsMsg(
              store.selectedOpenClass?.()?.crystalizationPeriod!,
            )();
            return {
              isInvalid,
              message,
            };
          },
          { isWarning: true },
        ),
      ]),
      crystalizationPeriod_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      highwaterMark_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      hasIncentiveFeeDiscounts_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      incFeeDiscountType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      incFeeDiscountRate_validators: computed(() => () => {
        let minRate = () =>
          store.selectedOpenClass?.()?.incFeeRateType ===
          Enums.IncFeeRateTypeEnum.SlidingScaleRate
            ? Math.min(
                ...[
                  store.filteredIncFeeRates?.()?.at(0)
                    ?.incFeeMaxRatePercentage ?? Number.MAX_SAFE_INTEGER,
                ],
              )
            : Math.max(
                ...[
                  store.filteredIncFeeRates?.()?.at(-1)?.incFeeRatePercentage ??
                    Number.MAX_SAFE_INTEGER,
                ],
              );

        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(0, { includeValue: true }),
          AksiaValidators.max(minRate, { includeValue: true }),
        ];
      }),
      incFeeDiscountDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),

      //#endregion

      //#region Closed Share Class Validators

      minimumLPCommitment_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10_000_000, { isWarning: true }),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap ??
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentTarget,
          {
            includeValue: () =>
              store.investmentVehicle?.()?.vEntityType ===
              Enums.VEntityTypeEnum.CommingledFund,
          },
        ),
        AksiaValidators.max(100_000_000, { isWarning: true }),
      ]),
      minimumLPCommitmentCurrency_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      mgmtFee_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      mgmtFeeTimeFrom_validators: computed(() => (period: MgmtFeePeriod) => {
        let i = () => store.filteredMgmtFeePeriods?.()?.indexOf(period) ?? 0;
        let first = () => i() === 0;
        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.userDefined(
            (value: unknown) => {
              if (first()) {
                return { isInvalid: false };
              }
              const previousPeriod = store
                .filteredMgmtFeePeriods?.()
                ?.at(i() - 1);
              const to = Enums.MgmtFeeFromEnum[
                Enums.MgmtFeeToEnum[
                  previousPeriod?.mgmtFeeTimeTo! as number
                ] as unknown as number
              ] as unknown as number;
              return { isInvalid: value !== to };
            },
            {
              isWarning: true,
              userMessage: "Is usually the same with the previous period's To",
            },
          ),
        ];
      }),
      mgmtFeeTimeFromDate_validators: computed(
        () => (period: MgmtFeePeriod) => {
          let i = () => store.filteredMgmtFeePeriods?.()?.indexOf(period) ?? 0;
          let first = () => i() === 0;

          return [
            AksiaValidators.minDate(
              !first()
                ? () =>
                    store.filteredMgmtFeePeriods?.()?.at(i() - 1)
                      ?.mgmtFeeTimeToDate
                : undefined,
              {
                isWarning: true,
                userMessage: () =>
                  `Is usually later than ${UTILS.FORMAT.toDateFormat(store.filteredMgmtFeePeriods?.()?.at(i() - 1)?.mgmtFeeTimeToDate)}`,
              },
            ),
            AksiaValidators.maxDate(() => period.mgmtFeeTimeToDate, {
              isWarning: true,
              userMessage: () =>
                `Is usually earlier than ${UTILS.FORMAT.toDateFormat(period.mgmtFeeTimeToDate)}`,
            }),
          ];
        },
      ),
      mgmtFeeTimeFromDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      mgmtFeeTimeTo_validators: computed(() => (period: MgmtFeePeriod) => {
        let i = () => store.filteredMgmtFeePeriods?.()?.indexOf(period) ?? 0;
        let last = () => i() === store.filteredMgmtFeePeriods?.()?.length! - 1;
        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.userDefined(
            (value: unknown) => {
              if (last()) {
                return { isInvalid: false };
              }
              const nextPeriod = store.filteredMgmtFeePeriods?.()?.at(i() + 1);
              const from = Enums.MgmtFeeToEnum[
                Enums.MgmtFeeFromEnum[
                  nextPeriod?.mgmtFeeTimeFrom! as number
                ] as unknown as number
              ] as unknown as number;
              return { isInvalid: value !== from };
            },
            {
              isWarning: true,
              userMessage: "Is usually the same with the next period's From",
            },
          ),
        ];
      }),
      mgmtFeeTimeToDate_validators: computed(() => (period: MgmtFeePeriod) => {
        let i = () => store.filteredMgmtFeePeriods?.()?.indexOf(period) ?? 0;
        let last = () => i() === store.filteredMgmtFeePeriods?.()?.length! - 1;

        return [
          AksiaValidators.minDate(() => period?.mgmtFeeTimeFromDate, {
            isWarning: true,
            userMessage: () =>
              `Is usually later than ${UTILS.FORMAT.toDateFormat(period?.mgmtFeeTimeFromDate)}`,
          }),
          AksiaValidators.maxDate(
            !last()
              ? () =>
                  store.filteredMgmtFeePeriods?.()?.at(i() + 1)
                    ?.mgmtFeeTimeFromDate
              : undefined,
            {
              isWarning: true,
              userMessage: () =>
                `Is usually earlier than ${UTILS.FORMAT.toDateFormat(store.filteredMgmtFeePeriods?.()?.at(i() + 1)?.mgmtFeeTimeFromDate!)}`,
            },
          ),
        ];
      }),
      mgmtFeeTimeToDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      mgmtFeeRate_validators: computed(() => (period: MgmtFeePeriod) => {
        let i = () => store.filteredMgmtFeePeriods?.()?.indexOf(period) ?? 0;
        let first = () => i() === 0;
        let previousPeriod = () =>
          first() ? undefined : store.filteredMgmtFeePeriods?.()?.at(i() - 1);
        let amounts = () => previousPeriod()?.mgmtFeeAmounts;
        let rates = () =>
          (amounts()
            ?.filter((a) => !a.$isDeleted && a.mgmtFeeRate)
            ?.map((a) => a.mgmtFeeRate) as Array<number>) ?? [];

        let maxRate = () =>
          rates()?.length! > 0 ? Math.min(Math.max(...rates()), 3) : 3;
        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(0, { includeValue: true }),
          AksiaValidators.max(100),
          AksiaValidators.max(() => maxRate(), { isWarning: true }),
        ];
      }),
      mgmtFeeCalcOnType_validators: computed(() => (period: MgmtFeePeriod) => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.notRepeated(
          () =>
            period?.mgmtFeeAmounts
              ?.filter((amount) => !amount.$isDeleted)
              ?.map((amount) => amount.mgmtFeeCalcOnType as number),
          {
            userMessage: 'Should not be selected more than once.',
          },
        ),
      ]),
      mgmtFeeCalcOnDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      paymentFrequency_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      mgmtFeeOffsetRate_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
      ]),
      hasMgmtFeeDiscounts_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      closedMgmtDiscountType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      closedMgmtDiscountRate_validators: computed(() => {
        let rates = () =>
          store
            .filteredMgmtFeePeriods?.()
            ?.flatMap((period: MgmtFeePeriod) =>
              period.mgmtFeeAmounts
                ?.filter((a) => !a.$isDeleted && a.mgmtFeeRate)
                ?.map((a) => a.mgmtFeeRate),
            )
            .filter((a) => a) as Array<number>;
        let maxRate = () => (rates()?.length! > 0 ? Math.max(...rates()) : 100);
        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(0, { includeValue: true }),
          AksiaValidators.max(() => maxRate()),
        ];
      }),
      closedMgmtDiscountDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedInterest_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedInterestTieredFeeRate_validators: computed(
        () => (interest: CarriedInterestTieredRate) => {
          let index = () =>
            store.filteredCarriedInterestTieredRates?.()?.indexOf(interest) ??
            0;
          let first = () => index() === 0;
          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(0, { includeValue: true }),
            AksiaValidators.min(() =>
              !first()
                ? (store.filteredCarriedInterestTieredRates?.()?.at(index() - 1)
                    ?.feeRate ?? 0)
                : 0,
            ),
            AksiaValidators.min(5, { isWarning: true }),
            AksiaValidators.max(
              () =>
                store.filteredCarriedInterestTieredRates?.()?.at(index() + 1)
                  ?.feeRate ?? 100,
            ),
            AksiaValidators.max(30, { isWarning: true }),
          ];
        },
      ),
      carriedInterestTieredPrefReturn_validators: computed(
        () => (interest: CarriedInterestTieredRate) => {
          let index = () =>
            store.filteredCarriedInterestTieredRates?.()?.indexOf(interest) ??
            0;
          let first = () => index() === 0;
          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(0, { includeValue: true }),
            AksiaValidators.min(() =>
              first()
                ? 0
                : (store.filteredCarriedInterestTieredRates?.()?.at(index() - 1)
                    ?.prefReturn ?? 0),
            ),
            AksiaValidators.min(5, { isWarning: true }),
            AksiaValidators.max(
              () =>
                store.filteredCarriedInterestTieredRates?.()?.at(index() + 1)
                  ?.prefReturn ?? 100,
            ),
            AksiaValidators.max(25, { isWarning: true }),
          ];
        },
      ),
      carriedInterestTieredMultiple_validators: computed(
        () => (interest: CarriedInterestTieredRate) => {
          let index = () =>
            store.filteredCarriedInterestTieredRates?.()?.indexOf(interest) ??
            0;
          let first = () => index() === 0;
          return [
            AksiaValidators.required({ isWarning: true }),
            AksiaValidators.min(0, { includeValue: true }),
            AksiaValidators.min(() =>
              first()
                ? 0
                : (store.filteredCarriedInterestTieredRates?.()?.at(index() - 1)
                    ?.multiple ?? 0),
            ),
            AksiaValidators.min(1.5, { isWarning: true }),
            AksiaValidators.max(
              () =>
                store.filteredCarriedInterestTieredRates?.()?.at(index() + 1)
                  ?.multiple ?? Number.POSITIVE_INFINITY,
            ),
            AksiaValidators.max(4, { isWarning: true }),
          ];
        },
      ),
      carriedInterestTieredCatchupRate_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
      ]),
      carriedInterestRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(5, { isWarning: true }),
        AksiaValidators.max(30, { isWarning: true }),
        AksiaValidators.max(100),
      ]),
      carriedInterestPrefReturnType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedInterestPrefReturnRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(5, { isWarning: true }),
        AksiaValidators.max(10, { isWarning: true }),
        AksiaValidators.max(100),
      ]),
      carriedInterestCatchUpRate_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
      ]),
      carriedInterestWaterfall_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedInterestWaterfallDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedInterestWaterfallFairValueTest_validators: computed(() => [
        AksiaValidators.min(100),
        AksiaValidators.min(110, { isWarning: true }),
        AksiaValidators.max(250),
        AksiaValidators.max(175, { isWarning: true }),
      ]),
      gpClawback_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      escrowAccountRate_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.max(100),
      ]),
      hasCarriedInterestDiscounts_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedIntDiscountType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedIntDiscountRate_validators: computed(() => {
        let rates = () =>
          store
            .filteredCarriedInterestTieredRates?.()
            ?.filter((a) => !a.$isDeleted && a.feeRate)
            ?.map((interest: CarriedInterestTieredRate) => interest.feeRate)
            .filter((a) => a) as Array<number>;
        let maxRate = () =>
          store.selectedClosedClass?.()?.carriedInterestRateType ===
          Enums.CarriedInterestRateTypeEnum.SingleRate
            ? (store.selectedClosedClass?.()?.carriedInterestRate ?? 100)
            : rates()?.length! > 0
              ? Math.max(...rates())
              : 100;
        return [
          AksiaValidators.required({ isWarning: true }),
          AksiaValidators.min(0, { includeValue: true }),
          AksiaValidators.max(() => maxRate()),
        ];
      }),
      carriedIntDiscountDesc_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),

      //#endregion
    })),
    withComputed((store) => ({
      //#region Open Share Class Validator Chains

      minimumInitialInvestment_validator_chain: computed(() => [
        `Minimum Additional Investment@${store.selectedOpenClass?.()?.$uid!}`,
      ]),
      minimumAdditionalInvestment_validator_chain: computed(() => [
        `Minimum Initial Investment@${store.selectedOpenClass?.()?.$uid!}`,
      ]),
      lockupDurationMinRange_validator_chain: computed(
        () => (lockup: Lockup) => {
          let i = () => store.filteredLockups?.()?.indexOf(lockup) ?? 0;

          let previousLockup = store.filteredLockups?.()?.at(i() - 1);
          let currentLockup = store.filteredLockups?.()?.at(i());
          let adjacentLockups = [
            ...(previousLockup
              ? [
                  `Lockup Duration Max Range #${i()}@${store.selectedOpenClass?.()?.$uid}_${previousLockup.$uid!}`,
                ]
              : []),
            ...(currentLockup
              ? [
                  `Lockup Duration Max Range #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${currentLockup.$uid!}`,
                ]
              : []),
          ];
          return adjacentLockups;
        },
      ),
      lockupDurationMaxRange_validator_chain: computed(
        () => (lockup: Lockup) => {
          let i = () => store.filteredLockups?.()?.indexOf(lockup) ?? 0;

          let currentLockup = store.filteredLockups?.()?.at(i());
          let nextLockup = store.filteredLockups?.()?.at(i() + 1);
          let adjacentLockups = [
            ...(currentLockup
              ? [
                  `Lockup Duration Min Range #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${currentLockup.$uid!}`,
                ]
              : []),
            ...(nextLockup
              ? [
                  `Lockup Duration Min Range #${i() + 2}@${store.selectedOpenClass?.()?.$uid}_${nextLockup.$uid!}`,
                ]
              : []),
          ];
          return adjacentLockups;
        },
      ),
      lockupFee_validator_chain: computed(() => (lockup: Lockup) => {
        let i = () => store.filteredLockups?.()?.indexOf(lockup) ?? 0;

        let previousLockup = store.filteredLockups?.()?.at(i() - 1);
        let nextLockup = store.filteredLockups?.()?.at(i() + 1);
        let adjacentLockups = [
          ...(previousLockup
            ? [
                `Lockup Fee #${i()}@${store.selectedOpenClass?.()?.$uid}_${previousLockup.$uid!}`,
              ]
            : []),
          ...(nextLockup
            ? [
                `Lockup Fee #${i() + 2}@${store.selectedOpenClass?.()?.$uid}_${nextLockup.$uid!}`,
              ]
            : []),
        ];
        return adjacentLockups;
      }),
      redemptionTermsFrequency_validator_chain: computed(() => [
        `Redemption Terms Frequency Amount@${store.selectedOpenClass?.()?.$uid}`,
      ]),
      gateLevel_validator_chain: computed(() => (exceptIndex: number) => {
        let otherGates = [
          ...(store
            .filteredGates?.()
            ?.map(
              (gate, i) =>
                `Gate Level #${i + 1}@${store.selectedOpenClass?.()?.$uid}_${gate.$uid!}`,
            )
            ?.filter?.((gate, index) => index !== exceptIndex) ?? []),
        ];

        return otherGates;
      }),
      mgmtFeeRatePercentage_validator_chain: computed(
        () => (feeRate: MgmtFeeRate) => {
          let i = () =>
            store.filteredOpenMgmtFeeRates?.()?.indexOf(feeRate) ?? 0;

          let previousFee = store.filteredOpenMgmtFeeRates?.()?.at(i() - 1);
          let nextFee = store.filteredOpenMgmtFeeRates?.()?.at(i() + 1);
          let adjacentFees = [
            ...(previousFee
              ? [
                  `Management Fee Rate Percentage #${i()}@${store.selectedOpenClass?.()?.$uid}_${previousFee.$uid!}`,
                ]
              : []),
            ...(nextFee
              ? [
                  `Management Fee Rate Percentage #${i() + 2}@${store.selectedOpenClass?.()?.$uid}_${nextFee.$uid!}`,
                ]
              : []),
          ];
          let discounts =
            store.filteredOpenMgmtFeeDiscounts?.()?.map((discount, i) => {
              return `Management Fee Discount Rate #${i + 1}@${store.selectedOpenClass?.()?.$uid}_${discount.$uid!}`;
            }) ?? [];
          return [...adjacentFees, ...discounts];
        },
      ),
      mgmtFeeRateAmountFrom_validator_chain: computed(
        () => (feeRate: MgmtFeeRate) => {
          let i = () =>
            store.filteredOpenMgmtFeeRates?.()?.indexOf(feeRate) ?? 0;

          let previousFee = store.filteredOpenMgmtFeeRates?.()?.at(i() - 1);
          let currentFee = store.filteredOpenMgmtFeeRates?.()?.at(i());
          let adjacentFees = [
            ...(previousFee
              ? [
                  `Management Fee Rate Amount To #${i()}@${store.selectedOpenClass?.()?.$uid}_${previousFee.$uid!}`,
                ]
              : []),
            ...(currentFee
              ? [
                  `Management Fee Rate Amount To #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${currentFee.$uid!}`,
                ]
              : []),
          ];
          return adjacentFees;
        },
      ),
      mgmtFeeRateAmountTo_validator_chain: computed(
        () => (feeRate: MgmtFeeRate) => {
          let i = () =>
            store.filteredOpenMgmtFeeRates?.()?.indexOf(feeRate) ?? 0;

          let nextFee = store.filteredOpenMgmtFeeRates?.()?.at(i() + 1);
          let currentFee = store.filteredOpenMgmtFeeRates?.()?.at(i());
          let adjacentFees = [
            ...(nextFee
              ? [
                  `Management Fee Rate Amount From #${i() + 2}@${store.selectedOpenClass?.()?.$uid}_${nextFee.$uid!}`,
                ]
              : []),
            ...(currentFee
              ? [
                  `Management Fee Rate Amount From #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${currentFee.$uid!}`,
                ]
              : []),
          ];
          return adjacentFees;
        },
      ),
      incFeeRatePercentage_validator_chain: computed(
        () => (incFeeRate: IncFeeRate) => {
          let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;

          let previousFee = store.filteredIncFeeRates?.()?.at(i() - 1);
          let nextFee = store.filteredIncFeeRates?.()?.at(i() + 1);
          let adjacentFees = [
            ...(previousFee
              ? [
                  `Incentive Fee Rate Percentage #${i()}@${store.selectedOpenClass?.()?.$uid}_${previousFee.$uid!}`,
                ]
              : []),
            ...(nextFee
              ? [
                  `Incentive Fee Rate Percentage #${i() + 2}@${store.selectedOpenClass?.()?.$uid}_${nextFee.$uid!}`,
                ]
              : []),
          ];
          let discounts =
            store.filteredIncFeeDiscounts?.()?.map((discount, i) => {
              return `Incentive Fee Discount Rate #${i + 1}@${store.selectedOpenClass?.()?.$uid}_${discount.$uid!}`;
            }) ?? [];
          return [...adjacentFees, ...discounts];
        },
      ),
      incFeeMinRatePercentage_validator_chain: computed(
        () => (incFeeRate: IncFeeRate) => {
          let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;
          return [
            `Incentive Fee Max Rate Percentage #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${incFeeRate.$uid!}`,
          ];
        },
      ),
      incFeeMaxRatePercentage_validator_chain: computed(
        () => (incFeeRate: IncFeeRate) => {
          let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;
          let discounts =
            store.filteredIncFeeDiscounts?.()?.map((discount, i) => {
              return `Incentive Fee Discount Rate #${i + 1}@${store.selectedOpenClass?.()?.$uid}_${discount.$uid!}`;
            }) ?? [];
          let maxRate = [
            `Incentive Fee Min Rate Percentage #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${incFeeRate.$uid!}`,
          ];
          return [...discounts, ...maxRate];
        },
      ),
      hurdleRate_validator_chain: computed(() => (incFeeRate: IncFeeRate) => {
        let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;

        let previousFee = store.filteredIncFeeRates?.()?.at(i() - 1);
        let nextFee = store.filteredIncFeeRates?.()?.at(i() + 1);
        let adjacentFees = [
          ...(previousFee
            ? [
                `Hurdle Rate #${i()}@${store.selectedOpenClass?.()?.$uid}_${previousFee.$uid!}`,
              ]
            : []),
          ...(nextFee
            ? [
                `Hurdle Rate #${i() + 2}@${store.selectedOpenClass?.()?.$uid}_${nextFee.$uid!}`,
              ]
            : []),
        ];
        return adjacentFees;
      }),
      hurdleMinRatePercentage_validator_chain: computed(
        () => (incFeeRate: IncFeeRate) => {
          let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;
          return [
            `Hurdle Max Rate Percentage #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${incFeeRate.$uid!}`,
          ];
        },
      ),
      hurdleMaxRatePercentage_validator_chain: computed(
        () => (incFeeRate: IncFeeRate) => {
          let i = () => store.filteredIncFeeRates?.()?.indexOf(incFeeRate) ?? 0;
          return [
            `Hurdle Min Rate Percentage #${i() + 1}@${store.selectedOpenClass?.()?.$uid}_${incFeeRate.$uid!}`,
          ];
        },
      ),
      crystalizationPeriod_validator_chain: computed(() => [
        `Crystalization Period Amount@${store.selectedOpenClass?.()?.$uid}`,
      ]),

      //#endregion

      //#region Closed Share Class Validator Chains

      commitmentCap_validator_chain: computed(() => [
        `Minimum LP Commitment@${store.selectedClosedClass?.()?.$uid}`,
        `Commitment Target@${store.selectedClosedClass?.()?.$uid}`,
      ]),

      commitmentTarget_validator_chain: computed(() => [
        `Minimum LP Commitment@${store.selectedClosedClass?.()?.$uid}`,
        `Commitment Target@${store.selectedClosedClass?.()?.$uid}`,
      ]),

      mgmtFeeTimeFrom_validator_chain: computed(
        () =>
          store
            .filteredMgmtFeePeriods?.()
            ?.map(
              (period, i) =>
                `Management Fee Time To #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${period.$uid!}`,
            ) ?? [],
      ),

      mgmtFeeTimeTo_validator_chain: computed(
        () =>
          store
            .filteredMgmtFeePeriods?.()
            ?.map(
              (period, i) =>
                `Management Fee Time From #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${period.$uid!}`,
            ) ?? [],
      ),

      mgmtFeeTimeFromDate_validator_chain: computed(
        () =>
          store
            .filteredMgmtFeePeriods?.()
            ?.map(
              (period, i) =>
                `Management Fee Time To Date #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${period.$uid!}`,
            ) ?? [],
      ),

      mgmtFeeTimeToDate_validator_chain: computed(
        () =>
          store
            .filteredMgmtFeePeriods?.()
            ?.map(
              (period, i) =>
                `Management Fee Time From Date #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${period.$uid!}`,
            ) ?? [],
      ),

      mgmtFeeRate_validator_chain: computed(() => [
        ...((store
          .filteredMgmtFeePeriods?.()
          ?.flatMap((period, i) =>
            period.mgmtFeeAmounts?.map(
              (amount, j) =>
                `Management Fee Period #${i + 1} - Rate #${j + 1}@${store.selectedClosedClass?.()?.$uid}_${amount.$uid!}`,
            ),
          )
          .filter(Boolean) as Array<string>) ?? []),
        ...((store
          .filteredClosedMgmtFeeDiscounts?.()
          ?.flatMap(
            (discount, i) =>
              `Management Fee Discount Rate #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${discount.$uid!}`,
          )
          .filter(Boolean) as Array<string>) ?? []),
      ]),

      mgmtFeeCalcOnType_validator_chain: computed(() => {
        return (
          (store
            .filteredMgmtFeePeriods?.()
            ?.flatMap((period, i) =>
              period.mgmtFeeAmounts?.map(
                (amount, j) =>
                  `Management Fee Period #${i + 1} - Calc On Type #${j + 1}@${store.selectedClosedClass?.()?.$uid}_${amount.$uid!}`,
              ),
            )
            .filter(Boolean) as Array<string>) ?? []
        );
      }),

      carriedInterestTieredFeeRate_validator_chain: computed(() => [
        ...((store
          .filteredCarriedInterestTieredRates?.()
          ?.map(
            (rate, i) =>
              `Carried Interest Rate #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${rate.$uid!}`,
          )
          .filter(Boolean) as Array<string>) ?? []),
        ...((store
          .filteredCarriedInterestDiscounts?.()
          ?.map(
            (discount, i) =>
              `Carried Interest Discount Rate #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${discount.$uid!}`,
          )
          .filter(Boolean) as Array<string>) ?? []),
      ]),

      carriedInterestTieredPrefReturn_validator_chain: computed(() => [
        ...((store
          .filteredCarriedInterestTieredRates?.()
          ?.flatMap(
            (rate, i) =>
              `Carried Interest Pref Return #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${rate.$uid!}`,
          )
          .filter(Boolean) as Array<string>) ?? []),
      ]),

      carriedInterestTieredMultiple_validator_chain: computed(() => [
        ...((store
          .filteredCarriedInterestTieredRates?.()
          ?.flatMap(
            (rate, i) =>
              `Carried Interest Multiple #${i + 1}@${store.selectedClosedClass?.()?.$uid}_${rate.$uid!}`,
          )
          .filter(Boolean) as Array<string>) ?? []),
      ]),

      //#endregion
    })),
  );
}
