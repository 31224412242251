import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import {
  ChipComponent,
  DropdownComponent,
  IconComponent,
  CopyDirective,
  ButtonComponent,
} from '@aksia/ui';
import {
  ENVIRONMENT_TOKEN,
  VALIDATION_SERVICE_TOKEN,
} from '@aksia/infrastructure';
import { AuthenticationService } from '../../services/authentication.service';
import { InvestmentVehicleStore } from '../../stores/investment-vehicle.store';
import { StaticContentDirective } from '../../contents/static-content.directive';

@Component({
  selector: 'page-header[uipageheader="investment-vehicle"]',
  imports: [
    CommonModule,
    RouterModule,
    ChipComponent,
    DropdownComponent,
    IconComponent,
    ButtonComponent,
    CopyDirective,
    RouterLink,
  ],
  template: `
    @let iv = store.investmentVehicle()!;

    @if (iv) {
      <chip
        IconArea
        class="asset-class"
        [class]="iv.type?.replaceAll(' ', '') || 'default'"
      ></chip>
      <div TitleArea>
        <h2 class="r1s1" TitleText [UiCopy]="iv.name!">
          {{ iv.name }}
        </h2>
        <div Subtitle>
          <div>
            <icon
              [iconSize]="'smaller'"
              [iconDefinition]="ICONS.company"
            ></icon>
            <a
              [routerLink]="['/managementcompany', iv.managementCompanyId]"
              target="_blank"
              [UiCopy]="iv.managementCompanyName!"
              >{{ iv.managementCompanyName || 'Management Company' }}</a
            >
          </div>
          <div>
            <icon [iconSize]="'smaller'" [iconDefinition]="ICONS.tag"></icon>
            <span [UiCopy]="iv.investmentProgramName!">{{
              iv.investmentProgramName || 'Investment Program'
            }}</span>
          </div>
          <div>
            <icon [iconSize]="'smaller'" [iconDefinition]="ICONS.link"></icon>
            <a
              [attr.href]="
                env.max_url +
                '/Application/Fund/Fund.aspx?dataProviderEntityId=' +
                iv.fundId
              "
              target="_blank"
              >View in MAX</a
            >
          </div>
        </div>
      </div>
      <div ControlsArea class="align-center justify-end">
        <btn
          class="primary"
          [title]="validation.stateSummary()"
          [settings]="{
            label: 'Save',
            disabled: validation.state() === 'pristine',
          }"
          [state]="validation.state()"
          (click)="store.saveInvestmentVehicle()"
        ></btn>
        <btn class="tertiary shadow-sm" [settings]="{ label: 'Cancel' }"></btn>
        <dropdown
          [settings]="{
            tag: 'View',
            optionLabel: 'value',
            optionValue: 'value',
            options: layout.filteredViews(),
            optionsFiltering: false,
          }"
          [value]="layout.selectedView()"
          (valueChange)="layout.selectView($event)"
        >
          @if (auth.user()?.isInRole(ROLES.ADMIN)) {
            <btn
              uitool
              [settings]="{
                tag: 'Set Default View',
                icon:
                  layout.defaultView() === layout.selectedView()
                    ? ICONS.defaultOn
                    : ICONS.defaultOff,
                iconSize: 'small',
              }"
              (click)="layout.setDefaultView(layout.selectedView()!)"
            ></btn>
          }
        </dropdown>
        @if (auth.user()?.isAdmin()) {
          <btn
            uiunpermissioned
            class="tertiary md"
            [settings]="{
              label: 'Download Permissions',
              icon: ICONS.download,
              iconSize: 'large',
              tag: 'Downlad Permissions',
            }"
            (click)="layout.downloadPermissions()"
          ></btn>
          <dropdown
            uiunpermissioned
            class="tertiary md"
            [(value)]="layout.editPermissionsForControls"
            [settings]="{
              label: 'Edit Permissions',
              options: [
                { value: 'PAGE-CONTENT', label: 'Page Content' },
                { value: 'SECTION', label: 'Section' },
                { value: 'SECTION-GROUP', label: 'Section Group' },
                { value: 'CONTROL', label: 'Control' },
              ],
              optionsFiltering: false,
              optionsSorting: false,
              tag: 'Edit Permissions',
            }"
          ></dropdown>
        }
      </div>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestmentVehiclePageHeader extends StaticContentDirective {
  //#region Injections

  protected readonly env = inject(ENVIRONMENT_TOKEN);
  protected readonly route = inject(ActivatedRoute);
  protected readonly auth = inject(AuthenticationService);
  protected readonly store = inject(InvestmentVehicleStore);
  protected readonly validation = inject(VALIDATION_SERVICE_TOKEN);

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uiview') get uiview() {
    return this.layout.selectedView();
  }

  //#endregion
}
