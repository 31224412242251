import { signalStoreFeature, type, withComputed } from '@ngrx/signals';
import {
  ClosedEndInterimDate,
  ContractualExtension,
  Enums,
  InvestmentVehicle,
  LpClawbackPerLimit,
  RecyclingTimeLimit,
} from '@aksia/models';
import { computed, Signal } from '@angular/core';
import { AksiaValidators, UTILS } from '@aksia/infrastructure';

const futureDateValidator = AksiaValidators.maxDate.bind(null, new Date(), {
  isWarning: true,
  userMessage: 'Is usually not occuring in the future',
});

export function withInvestmentVehicleValidators<EntityValidators>() {
  return signalStoreFeature(
    {
      computed: type<{
        investmentVehicle: Signal<InvestmentVehicle | undefined>;
      }>(),
    },
    withComputed((store) => ({
      filteredInterimDates: computed(() =>
        store
          .investmentVehicle()
          ?.closedEndDetails.interimDates?.filter(
            (interimDate) => !interimDate.$isDeleted,
          ),
      ),
    })),
    withComputed((store) => ({
      dateOfFormation_validators: computed(() => [futureDateValidator()]),
      commencementOfOperations_validators: computed(() => [
        futureDateValidator(),
        AksiaValidators.minDate(
          () => new Date(store.investmentVehicle?.()?.dateOfFormation!),
          {
            isWarning: true,
            userMessage: 'Is usually occuring after Date of Formation.',
          },
        ),
      ]),
      firstMgmtFeeAccrualDate_validators: computed(() => [
        futureDateValidator(),
      ]),
      firstDrawdownDueDate_validators: computed(() => [futureDateValidator()]),
      firstInvestmentDate_validators: computed(() => [futureDateValidator()]),
      firstCloseDate_validators: computed(() => [
        futureDateValidator(),
        AksiaValidators.minDate(
          () => store.investmentVehicle?.()?.dateOfFormation!,
          {
            isWarning: true,
            userMessage: 'Is usually occuring after Date of Formation.',
          },
        ),
        AksiaValidators.maxDate(
          () => store.investmentVehicle?.()?.finalCloseDate,
          {
            userMessage: 'First Close should occur on or before Final Close.',
          },
        ),
        AksiaValidators.userDefined((value: unknown) => {
          let isInvalid = false;
          let message = '';

          if (value) {
            store
              .filteredInterimDates()
              ?.forEach((interimDate: ClosedEndInterimDate) => {
                if (
                  interimDate.date &&
                  new Date(interimDate.date) <= new Date(value as string)
                ) {
                  isInvalid = true;
                  message = 'First Close should occur before Interim Close.';
                  return;
                }
              });
          }

          return {
            isInvalid,
            message,
          };
        }),
      ]),
      interimCloseDate_validators: computed(() => [
        futureDateValidator(),
        AksiaValidators.minDate(
          () => store.investmentVehicle?.()?.firstCloseDate,
          {
            includeValue: true,
            userMessage: 'Interim close should occur after First Close.',
          },
        ),
        AksiaValidators.maxDate(
          () => store.investmentVehicle?.()?.finalCloseDate,
          {
            includeValue: true,
            userMessage: 'Interim close should occur before Final Close.',
          },
        ),
      ]),
      finalCloseDate_validators: computed(() => [
        futureDateValidator(),
        AksiaValidators.minDate(
          () => store.investmentVehicle?.()?.firstCloseDate,
          {
            userMessage: 'Final Close should occur on or after First Close.',
          },
        ),
        AksiaValidators.userDefined((value: unknown) => {
          let isInvalid = false;
          let message = '';

          if (value) {
            store
              .filteredInterimDates()
              ?.forEach((interimDate: ClosedEndInterimDate) => {
                if (
                  interimDate.date &&
                  new Date(interimDate.date) >= new Date(value as string)
                ) {
                  isInvalid = true;
                  message = 'Final Close should occur after Interim Close.';
                  return;
                }
              });
          }

          return {
            isInvalid,
            message,
          };
        }),
      ]),
      effectiveDate_validators: computed(() => [
        futureDateValidator(),
        AksiaValidators.minDate(
          () => store.investmentVehicle?.()?.dateOfFormation,
          {
            isWarning: true,
            userMessage: 'Is usually occuring after Date of Formation.',
          },
        ),
      ]),
      contractualFinalCloseMonths_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(6, { isWarning: true }),
        AksiaValidators.max(24, { isWarning: true }),
      ]),
      contractualFinalCloseExtensionDuration_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(3, { isWarning: true }),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails
              .contractualFinalCloseMonths,
          { isWarning: true },
        ),
      ]),
      contractualInvestmentPeriodExpirationYears_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(2, { isWarning: true }),
        AksiaValidators.max(6, { isWarning: true }),
      ]),
      contractualInvestmentPeriodExtensionDuration_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(3, { isWarning: true }),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .contractualInvestmentPeriodExpirationYears,
          { isWarning: true },
        ),
      ]),
      contractualTermExpirationYears_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(2, { isWarning: true }),
        AksiaValidators.max(12, { isWarning: true }),
      ]),
      contractualTermExpirationExtensionDuration_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(3, { isWarning: true }),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .contractualTermExpirationYears,
          { isWarning: true },
        ),
      ]),
      commitmentTarget_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(500_000_000, { isWarning: true }),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap,
        ),
        AksiaValidators.max(25_000_000_000, { isWarning: true }),
      ]),
      hasCommitmentCap_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      commitmentCap_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentTarget,
        ),
        AksiaValidators.min(500_000_000, { isWarning: true }),
        AksiaValidators.max(25_000_000_000, { isWarning: true }),
      ]),
      commitmentCurrency_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      commitmentsAsOf_validators: computed(() => [futureDateValidator()]),
      fundCommitmentsTotal_validators: computed(() => [
        AksiaValidators.min(() =>
          Math.max(
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .fundCommitmentsGP ?? 0,
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .fundCommitmentsLP ?? 0,
          ),
        ),
        AksiaValidators.notEquals(
          () =>
            Math.max(
              store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                .fundCommitmentsGP ?? 0,
              store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                .fundCommitmentsLP ?? 0,
            ),
          {
            includeValue: true,
            isWarning: () =>
              UTILS.OBJECT.isNil(
                store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                  .fundCommitmentsGP,
              ) ||
              UTILS.OBJECT.isNil(
                store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                  .fundCommitmentsLP,
              ),
          },
        ),
      ]),
      fundCommitmentsLP_validators: computed(() => [
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .fundCommitmentsTotal,
        ),
        AksiaValidators.notEquals(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .fundCommitmentsTotal,
          {
            isWarning: () =>
              UTILS.OBJECT.isNil(
                store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                  .fundCommitmentsGP,
              ),
          },
        ),
      ]),
      fundCommitmentsGP_validators: computed(() => [
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .fundCommitmentsTotal,
        ),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .fundCommitmentsTotal,
          {
            includeValue: true,
            isWarning: () =>
              UTILS.OBJECT.isNil(
                store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                  .fundCommitmentsLP,
              ),
          },
        ),
      ]),
      minGPCommitment_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(1, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(5, { isWarning: true }),
      ]),
      minGPCommitmentAmount_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(
          () =>
            (store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap ??
              store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                .commitmentTarget ??
              Number.NEGATIVE_INFINITY) * 0.01,
          { isWarning: true },
        ),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap ??
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentTarget,
          { includeValue: true },
        ),
        AksiaValidators.max(
          () =>
            (store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap ??
              store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                .commitmentTarget ??
              Number.POSITIVE_INFINITY) * 0.05,
          { isWarning: true },
        ),
      ]),
      followOnInvestmentsPerLimit_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      followOnInvestmentsPerLimitRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(35, { isWarning: true }),
      ]),
      followOnInvestmentsTimeLimit_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      followOnInvestmentsTimeLimitMonths_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(3, { isWarning: true }),
        AksiaValidators.max(36, { isWarning: true }),
      ]),
      investmentsInProgressTimeLimit_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      investmentsInProgressTimeLimitMonths_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(3, { isWarning: true }),
        AksiaValidators.max(36, { isWarning: true }),
      ]),
      subsequentCloseInterest_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      subsequentCloseInterestType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      subsequentCloseInterestRate_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(2, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(10, { isWarning: true }),
      ]),
      organizationalExpenseCapRate_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(0.1, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(0.4, { isWarning: true }),
      ]),
      organizationalExpenseCapAmount_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(
          () =>
            (store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap ??
              store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                .commitmentTarget ??
              Number.NEGATIVE_INFINITY) * 0.001,
          { isWarning: true },
        ),
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap ??
            store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentTarget,
          { includeValue: true },
        ),
        AksiaValidators.max(
          () =>
            (store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
              .commitmentCap ??
              store.investmentVehicle?.()?.closedEndDetails.closedEndKeyTerm
                .commitmentTarget ??
              Number.POSITIVE_INFINITY) * 0.004,
          { isWarning: true },
        ),
      ]),
      timeLimitType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.notRepeated(
          () =>
            store
              .investmentVehicle()
              ?.closedEndDetails.recyclingTimeLimits?.filter(
                (recycle) => !recycle.$isDeleted,
              )
              ?.map((recycle) => recycle.timeLimitType as number),
          { userMessage: 'Should not be selected more than once.' },
        ),
      ]),
      monthsFromAcquisition_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(3, { isWarning: true }),
        AksiaValidators.max(36, { isWarning: true }),
      ]),
      recyclingCommitmentsRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.max(200, { isWarning: true }),
      ]),
      proceedsType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.notRepeated(
          () =>
            store
              .investmentVehicle()
              ?.closedEndDetails.recyclingProceedsLimits?.filter(
                (recycle) => !recycle.$isDeleted,
              )
              ?.map((recycle) => recycle.proceedsType as number),
          {
            userMessage: 'Should not be selected more than once.',
            excludeValue: Enums.RecyclingProceedsLimitEnum.Other,
          },
        ),
      ]),
      lpClawbackPerLimitRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(50, { isWarning: true }),
      ]),
      lpClawbackCalculatedOnType_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.notRepeated(
          () =>
            store
              .investmentVehicle()
              ?.closedEndDetails.lpClawbackPerLimits?.filter(
                (clawback) => !clawback.$isDeleted,
              )
              ?.map(
                (clawback) => clawback.lpClawbackCalculatedOnType as number,
              ),
          {
            userMessage: 'Should not be selected more than once.',
          },
        ),
      ]),
      lpClawbackTimeLimitYears_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(1, { isWarning: true }),
        AksiaValidators.max(5, { isWarning: true }),
      ]),
      lpClawbackTimeLimitFrom_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.notRepeated(
          () =>
            store
              .investmentVehicle()
              ?.closedEndDetails.lpClawbackTimeLimits?.filter(
                (recycle) => !recycle.$isDeleted,
              )
              ?.map((recycle) => recycle.lpClawbackTimeLimitFrom as number),
          {
            userMessage: 'Should not be selected more than once.',
          },
        ),
      ]),
      forFaultGpImRemoval_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      forFaultGpImVoteThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(75, { isWarning: true }),
      ]),
      carriedInterestReduction_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      carriedInterestReductionRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(10, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(50, { isWarning: true }),
      ]),
      forFaultInvPeriodTermination_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      forFaultInvPeriodVoteThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(75, { isWarning: true }),
      ]),
      forFaultFundTermination_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      forFaultFundVoteThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(75, { isWarning: true }),
      ]),
      noFaultGpImRemoval_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      noFaultGpImVoteThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(80, { isWarning: true }),
      ]),
      noFaultInvPeriodTermination_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      noFaultInvPeriodVoteThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(80, { isWarning: true }),
      ]),
      noFaultFundTermination_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      noFaultFundVoteThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(80, { isWarning: true }),
      ]),
      keyPersonProvision_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      keyPersonProVoteThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(75, { isWarning: true }),
      ]),
      quarterlyReportsDeadline_validators: computed(() => [
        AksiaValidators.min(30, { isWarning: true }),
        AksiaValidators.max(90, { isWarning: true }),
      ]),
      annualReportsDeadline_validators: computed(() => [
        AksiaValidators.min(60, { isWarning: true }),
        AksiaValidators.max(180, { isWarning: true }),
      ]),
      successorFundProvision_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      successorFundProvisionThresholdRate_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
        AksiaValidators.min(0, { includeValue: true }),
        AksiaValidators.min(50, { isWarning: true }),
        AksiaValidators.max(100),
        AksiaValidators.max(80, { isWarning: true }),
      ]),
      gpTargetReturnsGross_validators: computed(() => [
        AksiaValidators.min(
          () =>
            store.investmentVehicle?.()?.closedEndDetails
              .closedEndTargetReturnProfile.gpTargetReturnsNet,
          {
            userMessage:
              'Target Gross IRR cannot be lesser than the Target Net IRR',
          },
        ),
      ]),
      gpTargetReturnsNet_validators: computed(() => [
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails
              .closedEndTargetReturnProfile.gpTargetReturnsGross,
          {
            userMessage:
              'Target Net IRR cannot be greater than the Target Gross IRR',
          },
        ),
      ]),
      gpTargetGrossMultiple_validators: computed(() => [
        AksiaValidators.min(
          () =>
            store.investmentVehicle?.()?.closedEndDetails
              .closedEndTargetReturnProfile.gpTargetNetMultiple,
          {
            userMessage:
              'Target Gross TVPI cannot be lesser than the Target Net TVPI',
          },
        ),
      ]),
      gpTargetNetMultiple_validators: computed(() => [
        AksiaValidators.max(
          () =>
            store.investmentVehicle?.()?.closedEndDetails
              .closedEndTargetReturnProfile.gpTargetGrossMultiple,
          {
            userMessage:
              'Target Net TVPI cannot be greater than the Target Gross TVPI',
          },
        ),
      ]),
      leverageLimit_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      leverageBasis_validators: computed(() => [
        AksiaValidators.required({ isWarning: true }),
      ]),
      leveragePercentageMin_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
      ]),
      leveragePercentageMax_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
      ]),
      leveragePercentageTarget_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
      ]),
      leverageMultipleMin_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
      ]),
      leverageMultipleMax_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
      ]),
      leverageMultipleTarget_validators: computed(() => [
        AksiaValidators.min(0, { includeValue: true }),
      ]),
    })),
    withComputed((store) => ({
      dateOfFormation_validator_chain: computed(() => [
        `Commencement of Operations@${store.investmentVehicle()?.$uid}`,
        `Effective Date@${store.investmentVehicle()?.$uid}`,
      ]),
      firstCloseDate_validator_chain: computed(() => [
        `Date of Formation@${store.investmentVehicle()?.$uid}`,
        `Final Close Date@${store.investmentVehicle()?.$uid}`,
        ...(store
          .investmentVehicle?.()
          ?.closedEndDetails?.interimDates?.flatMap(
            (interimDate: ClosedEndInterimDate, i) =>
              `Interim Closed Date #${i + 1}@${store.investmentVehicle()?.$uid}_${interimDate.$uid}`,
          ) ?? []),
      ]),
      interimCloseDate_validator_chain: computed(() => [
        `First Close Date@${store.investmentVehicle()?.$uid}`,
        `Final Close Date@${store.investmentVehicle()?.$uid}`,
      ]),
      finalCloseDate_validator_chain: computed(() => [
        `First Close Date@${store.investmentVehicle()?.$uid}`,
        ...(store
          .investmentVehicle?.()
          ?.closedEndDetails?.interimDates?.flatMap(
            (interimDate: ClosedEndInterimDate, i) =>
              `Interim Closed Date #${i + 1}@${store.investmentVehicle()?.$uid}_${interimDate.$uid}`,
          ) ?? []),
      ]),
      contractualFinalCloseMonths_validator_chain: computed(() => [
        ...(store
          .investmentVehicle?.()
          ?.closedEndDetails?.contractualFinalCloseExtensions?.flatMap(
            (extension: ContractualExtension, i) =>
              `Contractual Final Close Extension Duration #${i + 1}@${store.investmentVehicle()?.$uid}_${extension.$uid}`,
          ) ?? []),
      ]),
      contractualInvestmentPeriodExpirationYears_validator_chain: computed(
        () => [
          ...(store
            .investmentVehicle?.()
            ?.closedEndDetails?.contractualInvestmentPeriodExtensions?.flatMap(
              (extension: ContractualExtension, i) =>
                `Contractual Investment Period Extension Duration #${i + 1}@${store.investmentVehicle()?.$uid}_${extension.$uid}`,
            ) ?? []),
        ],
      ),
      contractualTermExpirationYears_validator_chain: computed(() => [
        ...(store
          .investmentVehicle?.()
          ?.closedEndDetails?.contractualTermExtensions?.flatMap(
            (extension: ContractualExtension, i) =>
              `Contractual Term Extension Duration #${i + 1}@${store.investmentVehicle()?.$uid}_${extension.$uid}`,
          ) ?? []),
      ]),
      commitmentTarget_validator_chain: computed(() => [
        `Commitment Cap@${store.investmentVehicle()?.$uid}`,
        `Minimum GP Commitment Amount@${store.investmentVehicle()?.$uid}`,
        `Organizational Expense Cap Amount@${store.investmentVehicle()?.$uid}`,
      ]),
      commitmentCap_validator_chain: computed(() => [
        `Commitment Target@${store.investmentVehicle()?.$uid}`,
        `Minimum GP Commitment Amount@${store.investmentVehicle()?.$uid}`,
        `Organizational Expense Cap Amount@${store.investmentVehicle()?.$uid}`,
      ]),
      fundCommitmentsTotal_validator_chain: computed(() => [
        `Fund Commitments LP@${store.investmentVehicle()?.$uid}`,
        `Fund Commitments GP@${store.investmentVehicle()?.$uid}`,
      ]),
      fundCommitmentsLP_validator_chain: computed(() => [
        `Fund Commitments Total@${store.investmentVehicle()?.$uid}`,
        `Fund Commitments GP@${store.investmentVehicle()?.$uid}`,
      ]),
      fundCommitmentsGP_validator_chain: computed(() => [
        `Fund Commitments Total@${store.investmentVehicle()?.$uid}`,
        `Fund Commitments LP@${store.investmentVehicle()?.$uid}`,
      ]),
      timeLimitType_validator_chain: computed(
        () => (recycle: RecyclingTimeLimit) =>
          store
            .investmentVehicle()
            ?.closedEndDetails?.recyclingTimeLimits?.map(
              (recycle, i) =>
                `Time Limit Type #${i + 1}@${store.investmentVehicle()?.$uid}_${recycle.$uid}`,
            )!,
      ),
      proceedsType_validator_chain: computed(
        () => (recycle: RecyclingTimeLimit) =>
          store
            .investmentVehicle()
            ?.closedEndDetails?.recyclingProceedsLimits?.map(
              (recycle, i) =>
                `Proceeds Type #${i + 1}@${store.investmentVehicle()?.$uid}_${recycle.$uid}`,
            )!,
      ),
      lpClawbackCalculatedOnType_validator_chain: computed(
        () => (clawback: LpClawbackPerLimit) =>
          store
            .investmentVehicle()
            ?.closedEndDetails?.lpClawbackPerLimits?.map(
              (clawback, i) =>
                `LP Clawback Calculated On Type #${i + 1}@${store.investmentVehicle()?.$uid}_${clawback.$uid}`,
            )!,
      ),
      lpClawbackTimeLimitFrom_validator_chain: computed(
        () => (clawback: LpClawbackPerLimit) =>
          store
            .investmentVehicle()
            ?.closedEndDetails?.lpClawbackTimeLimits?.map(
              (clawback, i) =>
                `LP Clawback Time Limit From #${i + 1}@${store.investmentVehicle()?.$uid}_${clawback.$uid}`,
            )!,
      ),
      gpTargetReturnsGross_validator_chain: computed(() => [
        `GP Target Returns Net@${store.investmentVehicle()?.$uid}`,
      ]),
      gpTargetReturnsNet_validator_chain: computed(() => [
        `GP Target Returns Gross@${store.investmentVehicle()?.$uid}`,
      ]),
      gpTargetGrossMultiple_validator_chain: computed(() => [
        `GP Target Net Multiple@${store.investmentVehicle()?.$uid}`,
      ]),
      gpTargetNetMultiple_validator_chain: computed(() => [
        `GP Target Gross Multiple@${store.investmentVehicle()?.$uid}`,
      ]),
    })),
  );
}
