@let shareClass = ivStore.selectedClosedClass();

@if (shareClass) {
  <dropdown
    class="c1s2"
    [(value)]="shareClass.carriedInterest"
    [settings]="{
      label: 'Carried Interest',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      optionsFiltering: false,
      tag: 'Has Carried Interest',
    }"
    [validators]="ivStore.carriedInterest_validators()"
    [validation.id]="shareClass.$uid"
    (valueChange)="ivStore.updateCarriedInterest($event)"
  ></dropdown>

  @if (shareClass.carriedInterest === ENUMS.SimpleAnswerEnum.Yes) {
    <dropdown
      class="c3s2"
      [(value)]="shareClass.carriedInterestRateType"
      [settings]="{
        label: 'Rate Type',
        options: ENUMS.CarriedInterestRateTypeEnum.toOptions,
        optionsFiltering: false,
        tag: 'Carried Interest Rate Type',
      }"
      (valueChange)="ivStore.updateCarriedInterestRateType($event)"
    ></dropdown>
  }
  @if (
    shareClass.carriedInterestRateType ===
    ENUMS.CarriedInterestRateTypeEnum.TieredRate
  ) {
    <dropdown
      class="c5s2"
      [(value)]="shareClass.carriedInterestTieredBaseType"
      [settings]="{
        label: 'Based On',
        options: ENUMS.CarriedInterestTieredBaseEnum.toOptions,
        optionsFiltering: false,
        tag: 'Carried Interest Tiered Base Type',
      }"
      (valueChange)="ivStore.updateCarriedInterestTieredBaseType($event)"
    ></dropdown>
  }
  @if (
    shareClass.carriedInterestRateType ===
      ENUMS.CarriedInterestRateTypeEnum.TieredRate &&
    shareClass.carriedInterestTieredBaseType
  ) {
    <section-group
      class="c1s6"
      [settings]="{
        label: 'Carried Interest Rates',
      }"
    >
      @for (
        tieredRate of ivStore.filteredCarriedInterestTieredRates();
        track tieredRate.$uid;
        let i = $index;
        let c = $count
      ) {
        <number
          class="c1s1"
          [(value)]="tieredRate.feeRate"
          [settings]="{
            label: 'Carried Interest Rate ' + (c > 1 ? '#' + (i + 1) : ''),
            placeholder: '%',
            suffix: ' %',
            tag: 'Carried Interest Rate #' + (i + 1),
          }"
          [validators]="
            ivStore.carriedInterestTieredFeeRate_validators()(tieredRate)
          "
          [validation.id]="shareClass.$uid! + '_' + tieredRate.$uid!"
          [validation.chain]="
            ivStore.carriedInterestTieredFeeRate_validator_chain()
          "
        ></number>

        @if (
          shareClass.carriedInterestTieredBaseType !==
          ENUMS.CarriedInterestTieredBaseEnum.Multiple
        ) {
          <number
            [class.c2s2]="
              shareClass.carriedInterestTieredBaseType ===
              ENUMS.CarriedInterestTieredBaseEnum.PreferredReturn
            "
            [class.c2s1]="
              shareClass.carriedInterestTieredBaseType ===
              ENUMS.CarriedInterestTieredBaseEnum.PreferredReturn_and_Multiple
            "
            [(value)]="tieredRate.prefReturn"
            [settings]="{
              label: 'Hurdle/IRR ' + (c > 1 ? '#' + (i + 1) : ''),
              placeholder: '%',
              prefix: i === c - 1 ? 'above ' : 'until ',
              suffix: ' %',
              tag: 'Carried Interest Pref Return #' + (i + 1),
            }"
            [validators]="
              ivStore.carriedInterestTieredPrefReturn_validators()(tieredRate)
            "
            [validation.id]="shareClass.$uid! + '_' + tieredRate.$uid!"
            [validation.chain]="
              ivStore.carriedInterestTieredPrefReturn_validator_chain()
            "
          ></number>
        }
        @if (
          shareClass.carriedInterestTieredBaseType !==
          ENUMS.CarriedInterestTieredBaseEnum.PreferredReturn
        ) {
          <number
            [class.c2s2]="
              shareClass.carriedInterestTieredBaseType ===
              ENUMS.CarriedInterestTieredBaseEnum.Multiple
            "
            [class.c3s1]="
              shareClass.carriedInterestTieredBaseType ===
              ENUMS.CarriedInterestTieredBaseEnum.PreferredReturn_and_Multiple
            "
            [(value)]="tieredRate.multiple"
            [settings]="{
              label: 'Multiple ' + (c > 1 ? '#' + (i + 1) : ''),
              placeholder: 'x',
              prefix: i === c - 1 ? 'above ' : 'until ',
              suffix: 'x',
              tag: 'Carried Interest Multiple #' + (i + 1),
            }"
            [validators]="
              ivStore.carriedInterestTieredMultiple_validators()(tieredRate)
            "
            [validation.id]="shareClass.$uid! + '_' + tieredRate.$uid!"
            [validation.chain]="
              ivStore.carriedInterestTieredMultiple_validator_chain()
            "
          ></number>
        }

        <dropdown
          class="c4s1"
          [(value)]="tieredRate.hasCatchup"
          [settings]="{
            label: 'Catch-up ' + (c > 1 ? '#' + (i + 1) : ''),
            options: ENUMS.SimpleAnswerEnum.toOptions,
            optionsFiltering: false,
            tag: 'Carried Interest Catch-up #' + (i + 1),
          }"
          (valueChange)="
            ivStore.updateCarriedInterestTieredCatchup(tieredRate, $event)
          "
        ></dropdown>

        @if (tieredRate.hasCatchup === ENUMS.SimpleAnswerEnum.Yes) {
          <number
            class="c5s1"
            [(value)]="tieredRate.catchupRate"
            [settings]="{
              label: 'Catch-up % ' + (c > 1 ? '#' + (i + 1) : ''),
              placeholder: '%',
              suffix: ' %',
              tag: 'Carried Interest Catch-up Rate #' + (i + 1),
            }"
            [validators]="ivStore.carriedInterestTieredCatchupRate_validators()"
            [validation.id]="shareClass.$uid! + '_' + tieredRate.$uid!"
          ></number>
        }

        <div class="c6s1 flex justify-end">
          @if (i > 1) {
            <btn
              [settings]="{
                icon: ICONS.remove,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Remove Carried Interest Tiered Rate #' + (i + 1),
              }"
              (click)="ivStore.removeCarriedInterestTieredRate(tieredRate)"
            ></btn>
          }
          @if (i === c - 1) {
            <btn
              [settings]="{
                icon: ICONS.add,
                iconOnly: true,
                iconSize: 'normal',
                tag: 'Add Carried Interest Tiered Rate',
              }"
              (click)="ivStore.addCarriedInterestTieredRate()"
            ></btn>
          }
        </div>
      }
    </section-group>
  }
  @if (
    shareClass.carriedInterestRateType ===
    ENUMS.CarriedInterestRateTypeEnum.SingleRate
  ) {
    <number
      class="c1s2"
      [(value)]="shareClass.carriedInterestRate"
      [settings]="{
        label: 'Carried Interest %',
        placeholder: '%',
        suffix: ' %',
        tag: 'Carried Interest Rate',
      }"
      [validators]="ivStore.carriedInterestRate_validators()"
      [validation.id]="shareClass.$uid"
      [validation.chain]="
        ivStore.carriedInterestTieredFeeRate_validator_chain()
      "
    ></number>
  }
  @if (shareClass.carriedInterest === ENUMS.SimpleAnswerEnum.Yes) {
    <dropdown
      [class]="
        shareClass.carriedInterestRateType ===
        ENUMS.CarriedInterestRateTypeEnum.SingleRate
          ? 'c3s2'
          : 'c1s2'
      "
      [(value)]="shareClass.carriedInterestPrefReturnType"
      [settings]="{
        label: 'Pref Return',
        options: ENUMS.PrefReturnEnum.toOptions,
        optionsFiltering: false,
        tag: 'Carried Interest Pref Return Type',
      }"
      [validators]="ivStore.carriedInterestPrefReturnType_validators()"
      [validation.id]="shareClass.$uid"
      (valueChange)="ivStore.updateCarriedInterestPrefReturnType($event)"
    ></dropdown>
  }
  @if (
    ivStore.selectedClosedClass()!.carriedInterestPrefReturnType! >
    ENUMS.PrefReturnEnum.No
  ) {
    <number
      class="c1s2"
      [(value)]="shareClass.carriedInterestPrefReturnRate"
      [settings]="{
        label: 'Pref Return %',
        placeholder: '%',
        suffix: ' %',
        tag: 'Carried Interest Pref Return Rate',
      }"
      [validators]="ivStore.carriedInterestPrefReturnRate_validators()"
      [validation.id]="shareClass.$uid"
    ></number>

    @if (
      shareClass.carriedInterestPrefReturnType ===
      ENUMS.PrefReturnEnum.YesWithCatch_Up
    ) {
      <number
        class="c3s2"
        [(value)]="shareClass.carriedInterestCatchUpRate"
        [settings]="{
          label: 'Catch-Up',
          placeholder: '%',
          suffix: ' %',
          tag: 'Carried Interest Catch-Up Rate',
        }"
        [validators]="ivStore.carriedInterestCatchUpRate_validators()"
        [validation.id]="shareClass.$uid"
      ></number>
    }
  }
  @if (shareClass.carriedInterest === ENUMS.SimpleAnswerEnum.Yes) {
    <dropdown
      class="c1s2"
      [(value)]="shareClass.carriedInterestWaterfall"
      [settings]="{
        label: 'Waterfall',
        options: ENUMS.WaterfallEnum.toOptions,
        optionsFiltering: false,
        tag: 'Carried Interest Waterfall',
      }"
      [validators]="ivStore.carriedInterestWaterfall_validators()"
      [validation.id]="shareClass.$uid"
      (valueChange)="ivStore.updateCarriedInterestWaterfall($event)"
    ></dropdown>

    @if (shareClass.carriedInterestWaterfall === ENUMS.WaterfallEnum.Other) {
      <text
        class="c3s2"
        [(value)]="shareClass.carriedInterestWaterfallDesc"
        [settings]="{
          label: 'Waterfall (Description)',
          tag: 'Carried Interest Waterfall Desc',
        }"
        [validators]="ivStore.carriedInterestWaterfallDesc_validators()"
        [validation.id]="shareClass.$uid"
      ></text>
    }
    @if (
      shareClass.carriedInterestWaterfall ===
      ENUMS.WaterfallEnum.American___Fair_Value_Test__
    ) {
      <number
        class="c3s2"
        [(value)]="shareClass.carriedInterestWaterfallFairValueTest"
        [settings]="{
          label: 'Fair Value Test',
          placeholder: '%',
          suffix: ' %',
          tag: 'Carried Interest Waterfall Fair Value Test',
        }"
        [validators]="
          ivStore.carriedInterestWaterfallFairValueTest_validators()
        "
        [validation.id]="shareClass.$uid"
      ></number>
    }
  }
  @if (shareClass.carriedInterest === ENUMS.SimpleAnswerEnum.Yes) {
    <dropdown
      class="c1s2"
      [(value)]="shareClass.gpClawback"
      [settings]="{
        label: 'GP Clawback',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
      }"
      [validators]="ivStore.gpClawback_validators()"
      [validation.id]="shareClass.$uid"
      (valueChange)="ivStore.updateGpClawback($event)"
    ></dropdown>

    @if (shareClass.gpClawback === ENUMS.SimpleAnswerEnum.Yes) {
      <dropdown
        class="c3s1"
        [(value)]="shareClass.interimCalculations"
        [settings]="{
          label: 'Interim Calculations',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          optionsFiltering: false,
        }"
      ></dropdown>
      <dropdown
        class="c4s1"
        [(value)]="shareClass.escrowAccount"
        [settings]="{
          label: 'Escrow Account',
          options: ENUMS.SimpleAnswerEnum.toOptions,
          optionsFiltering: false,
        }"
        (valueChange)="ivStore.updateEscrowAccount($event)"
      ></dropdown>
    }
    @if (
      shareClass.gpClawback === ENUMS.SimpleAnswerEnum.Yes &&
      shareClass.escrowAccount === ENUMS.SimpleAnswerEnum.Yes
    ) {
      <number
        class="c5s1"
        [(value)]="shareClass.escrowAccountRate"
        [settings]="{
          label: 'Escrow Account (Rate)',
          placeholder: '% of Carry Held in Escrow',
          suffix: ' % of Carry Held in Escrow',
          tag: 'Escrow Account Rate',
        }"
        [validators]="ivStore.escrowAccountRate_validators()"
        [validation.id]="shareClass.$uid"
      ></number>
    }
    @if (shareClass.gpClawback === ENUMS.SimpleAnswerEnum.Yes) {
      <dropdown
        class="c6s1"
        [(value)]="shareClass.clawbackGuaranteeType"
        [settings]="{
          label: 'Clawback Guarantee',
          options: ENUMS.ClawbackGuaranteeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Clawback Guarantee Type',
        }"
      ></dropdown>
    }
  }
  @if (shareClass.carriedInterest === ENUMS.SimpleAnswerEnum.Yes) {
    <dropdown
      class="c1s2"
      [(value)]="shareClass.hasCarriedInterestDiscounts"
      [settings]="{
        label: 'Discount',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        optionsFiltering: false,
        tag: 'Has Carried Interest Discounts',
      }"
      [validators]="ivStore.hasCarriedInterestDiscounts_validators()"
      [validation.id]="shareClass.$uid"
      (valueChange)="ivStore.updateHasCarriedInterestDiscounts($event)"
    ></dropdown>

    @for (
      discount of ivStore.filteredCarriedInterestDiscounts();
      track discount.$uid;
      let i = $index;
      let c = $count
    ) {
      <dropdown
        class="c3s1"
        [(value)]="discount.discountType"
        [settings]="{
          label: 'Discount Type ' + (c > 1 ? '#' + (i + 1) : ''),
          options: ENUMS.DiscountTypeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Carried Interest Discount Type #' + (i + 1),
        }"
        [validators]="ivStore.carriedIntDiscountType_validators()"
        [validation.id]="shareClass.$uid! + '_' + discount.$uid!"
      ></dropdown>
      <number
        class="c4s1"
        [(value)]="discount.discountRate"
        [settings]="{
          label: 'Fee Reduction ' + (c > 1 ? '#' + (i + 1) : ''),
          labelTip:
            'Reflects the reduction granted, not the fee charged post-reduction.',
          placeholder: '%',
          suffix: ' %',
          tag: 'Carried Interest Discount Rate #' + (i + 1),
        }"
        [validators]="ivStore.carriedIntDiscountRate_validators()"
        [validation.id]="shareClass.$uid! + '_' + discount.$uid!"
      ></number>
      <text
        class="c5s2"
        [(value)]="discount.discountDesc"
        [settings]="{
          label: 'Eligibility ' + (c > 1 ? '#' + (i + 1) : ''),
          tag: 'Carried Interest Discount Desc #' + (i + 1),
        }"
        [validators]="ivStore.carriedIntDiscountDesc_validators()"
        [validation.id]="shareClass.$uid! + '_' + discount.$uid!"
      ></text>
      <div class="c7s1 flex place-self-stretch">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Carried Interest Discount #' + (i + 1),
            }"
            (click)="ivStore.removeCarriedInterestDiscount(discount)"
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Carried Interest Discount',
            }"
            (click)="ivStore.addCarriedInterestDiscount()"
          ></btn>
        }
      </div>
    }
  }
}
