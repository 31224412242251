@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <date
    class="c1s1"
    [(value)]="iv.dateOfFormation"
    [settings]="{
      label: 'Date of Formation',
      placeholder: 'mm/dd/yyyy',
      labelTip: 'Insert the date the fund was established/incorporated.',
    }"
    [validators]="ivStore.dateOfFormation_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.dateOfFormation_validator_chain()"
  ></date>
  <number
    class="c2s1"
    [(value)]="iv.vintage"
    [settings]="{
      label: 'Vintage Year per Aksia',
      labelTip:
        'Vintage Year is calculated using available data  in the following order :\r\n1) Earlier of: Date of 1st Management Fee Accrual, Date of 1st Drawdown Due Date, 1st Investment Date, or Commencement of Operations; 2) Vintage year provided by the manager; 3) Vintage year provided by third party; 4) First close date; 5) Final close date; 6) Inception date; 7) Date of Formation',
      onlyWholeNumbers: true,
      thousandsSeparator: '',
    }"
  ></number>
  <dropdown
    class="c1s1"
    [(value)]="iv.firstCloseDateType"
    [settings]="{
      label: 'First Close Date',
      labelTip: 'Also known as: \n\r Est. First Close Date',
      options: ENUMS.MinGPCommitmentEnum.toOptions,
      tag: 'First Close Date Type',
    }"
  ></dropdown>
  <date
    class="c2s1"
    [(value)]="iv.firstCloseDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: '',
      tag: 'First Close Date',
    }"
    [validators]="ivStore.firstCloseDate_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.firstCloseDate_validator_chain()"
  ></date>
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.effectiveDateType"
    [settings]="{
      label: 'Effective Date',
      labelFocused: '',
      options: ENUMS.CloseDateTypeEnum.toOptions,
      tag: 'Effective Date Type',
    }"
  ></dropdown>
  <date
    class="c2s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.effectiveDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: '',
      labelTip: 'Also known as \n\r Program Status as of',
      tag: 'Effective Date',
    }"
    [validators]="ivStore.effectiveDate_validators()"
    [validation.id]="iv.$uid"
  ></date>
  <text
    class="c3s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.effectiveDateDesc"
    [settings]="{
      label: '',
      labelFocused: '',
      placeholder: 'Description',
      placeholderPersists: true,
      tag: 'Effective Date Description',
    }"
  ></text>
  <section-group
    class="c1s4"
    [settings]="{
      label: 'Contractual Final Close',
    }"
  >
    <dropdown
      class="c1s1"
      [(value)]="iv.closedEndDetails.contractualFinalCloseDateType"
      [settings]="{
        label: 'Contractual Final Close',
        tag: 'Contractual Final Close Date Type',
        options: ENUMS.ContractualDateTypeEnum.toOptions,
      }"
      (valueChange)="ivStore.updateContractualFinalCloseDateType($event)"
    ></dropdown>

    @if (
      iv.closedEndDetails.contractualFinalCloseDateType ===
      ENUMS.ContractualDateTypeEnum.Fixed_date
    ) {
      <date
        class="c2s1"
        [(value)]="iv.closedEndDetails.contractualFinalCloseDate"
        [settings]="{
          label: 'mm/dd/yyyy',
          labelFocused: '',
          tag: 'Contractual Final Close Date',
        }"
      ></date>
    }
    @if (
      iv.closedEndDetails.contractualFinalCloseDateType ===
      ENUMS.ContractualDateTypeEnum.Relative_to_other_date
    ) {
      <text
        class="c2s2"
        [(value)]="iv.closedEndDetails.contractualFinalCloseDateDesc"
        [settings]="{
          label: '',
          placeholder: 'Description',
          tag: 'Contractual Final Close Date Description',
        }"
      ></text>
    }
    @if (
      iv.closedEndDetails.contractualFinalCloseDateType &&
      iv.closedEndDetails.contractualFinalCloseDateType !==
        ENUMS.ContractualDateTypeEnum.Fixed_date
    ) {
      <number
        [class]="
          iv.closedEndDetails.contractualFinalCloseDateType ===
          ENUMS.ContractualDateTypeEnum.Relative_to_other_date
            ? 'c4s1'
            : 'c2s1'
        "
        [(value)]="iv.closedEndDetails.contractualFinalCloseMonths"
        [settings]="{
          label: 'within # months',
          labelFocused: 'within',
          placeholder: 'months',
          suffix: ' months',
          tag: 'Contractual Final Close Months',
        }"
        [validators]="ivStore.contractualFinalCloseMonths_validators()"
        [validation.id]="iv.$uid"
        [validation.chain]="
          ivStore.contractualFinalCloseMonths_validator_chain()
        "
      ></number>
    }
    @for (
      extension of iv.closedEndDetails.contractualFinalCloseExtensions;
      track extension.$uid;
      let i = $index, c = $count
    ) {
      <number
        class="c1s1"
        [(value)]="extension.duration"
        [settings]="{
          label: c > 1 ? 'Extension #' + (i + 1) : 'Extension',
          placeholder: 'months',
          suffix: ' months',
          tag: 'Contractual Final Close Extension Duration #' + (i + 1),
        }"
        [validators]="
          ivStore.contractualFinalCloseExtensionDuration_validators()
        "
        [validation.id]="iv.$uid + '_' + extension.$uid"
      ></number>
      <dropdown
        class="c2s1"
        [(value)]="extension.type"
        [settings]="{
          label: ' ',
          options: ENUMS.ExtensionTypeEnum.toOptions,
          tag: 'Contractual Final Close Extension Type #' + (i + 1),
        }"
      ></dropdown>

      @if (i === c - 1) {
        <check
          class="c3s1 justify-center"
          [(value)]="
            iv.closedEndDetails.contractualFinalCloseUnlimitedExtensions
          "
          [settings]="{
            label: 'Unlimited Extensions',
            tag: 'Contractual Final Close Unlimited Extensions',
          }"
        ></check>
      }

      <div class="c4s1 flex place-self-stretch">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Contractual Final Close Extension #' + (i + 1),
            }"
            (click)="ivStore.removeContractualFinalCloseExtension(extension)"
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Contractual Final Close Extension',
            }"
            (click)="ivStore.addContractualFinalCloseExtension()"
          ></btn>
        }
      </div>
    }
  </section-group>
  <section-group
    class="c1s4"
    [settings]="{
      label: 'Contractual Investment Period Expiration',
    }"
  >
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndKeyTerm
          .contractualInvestmentPeriodExpirationYears
      "
      [settings]="{
        label: 'Contractual Investment Period Expiration',
        placeholder: 'years',
        suffix: ' years',
        labelTip: 'Also known as: \n\r Investment Period',
        tag: 'Contractual Investment Period Expiration Years',
      }"
      [validators]="
        ivStore.contractualInvestmentPeriodExpirationYears_validators()
      "
      [validation.id]="iv.$uid"
      (valueChange)="
        ivStore.updateContractualInvestmentPeriodExpirationYears($event)
      "
      [validation.chain]="
        ivStore.contractualInvestmentPeriodExpirationYears_validator_chain()
      "
    ></number>

    @if (
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationYears
    ) {
      <dropdown
        [class]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationType ===
          ENUMS.PeriodTypeEnum.FollowingOtherDate
            ? 'c2s1'
            : 'c2s2'
        "
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationType
        "
        [settings]="{
          label: '',
          labelTip: 'Also known as: \n\r Investment Period Base',
          options: ENUMS.PeriodTypeEnum.toOptions,
          tag: 'Contractual Investment Period Expiration Type',
        }"
        (valueChange)="
          ivStore.updateContractualInvestmentPeriodExpirationType($event)
        "
      ></dropdown>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <date
        class="c3s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationDate
        "
        [settings]="{
          label: 'mm/dd/yyyy',
          labelFocused: '',
          tag: 'Contractual Investment Period Expiration Date',
        }"
      ></date>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <text
        class="c4s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationDesc
        "
        [settings]="{
          label: '',
          placeholder: 'Description',
          tag: 'Contractual Investment Period Expiration Description',
        }"
      ></text>
    }
    @for (
      extension of iv.closedEndDetails.contractualInvestmentPeriodExtensions;
      track extension.$uid;
      let i = $index, c = $count
    ) {
      <number
        class="c1s1"
        [(value)]="extension.duration"
        [settings]="{
          label: c > 1 ? 'Extension #' + (i + 1) : 'Extension',
          placeholder: 'years',
          suffix: ' years',
          tag: 'Contractual Investment Period Extension Duration #' + (i + 1),
        }"
        [validators]="
          ivStore.contractualInvestmentPeriodExtensionDuration_validators()
        "
        [validation.id]="iv.$uid + '_' + extension.$uid"
      ></number>
      <dropdown
        class="c2s1"
        [(value)]="extension.type"
        [settings]="{
          label: ' ',
          options: ENUMS.ExtensionTypeEnum.toOptions,
          tag: 'Contractual Investment Period Extension Type #' + (i + 1),
        }"
      ></dropdown>

      @if (i === c - 1) {
        <check
          class="c3s1 justify-center"
          [(value)]="
            iv.closedEndDetails.closedEndKeyTerm
              .hasContractualInvestmentPeriodUnlimitedExtensions
          "
          [settings]="{
            label: 'Unlimited Extensions',
            tag: 'Contractual Investment Period Unlimited Extensions',
          }"
        ></check>
      }

      <div class="c4s1 flex place-self-stretch">
        @if (i > 0) {
          <btn
            *ngIf="i > 0"
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Contractual Investment Period Extension #' + (i + 1),
            }"
            (click)="
              ivStore.removeContractualInvestmentPeriodExtension(extension)
            "
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            *ngIf="i === c - 1"
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Contractual Investment Period Extension',
            }"
            (click)="ivStore.addContractualInvestmentPeriodExtension()"
          ></btn>
        }
      </div>
    }
  </section-group>
  <section-group
    class="c1s4"
    [settings]="{
      label: 'Contractual Term Expiration',
    }"
  >
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears
      "
      [settings]="{
        label: 'Contractual Term Expiration',
        placeholder: 'years',
        suffix: ' years',
        tag: 'Contractual Term Expiration Years',
      }"
      [validators]="ivStore.contractualTermExpirationYears_validators()"
      [validation.id]="iv.$uid"
      [validation.chain]="
        ivStore.contractualTermExpirationYears_validator_chain()
      "
      (valueChange)="ivStore.updateContractualTermExpirationYears($event)"
    ></number>

    @if (iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears) {
      <dropdown
        [class]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType ===
          ENUMS.PeriodTypeEnum.FollowingOtherDate
            ? 'c2s1'
            : 'c2s2'
        "
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType
        "
        [settings]="{
          label: '',
          options: ENUMS.PeriodTypeEnum.toOptions,
          tag: 'Contractual Term Expiration Type',
        }"
        (valueChange)="ivStore.updateContractualTermExpirationYears($event)"
      ></dropdown>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <date
        class="c3s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDate
        "
        [settings]="{
          label: 'mm/dd/yyyy',
          labelFocused: '',
          tag: 'Contractual Term Expiration Date',
        }"
      ></date>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <text
        class="c4s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDesc
        "
        [settings]="{
          label: '',
          placeholder: 'Description',
          tag: 'Contractual Term Expiration Description',
        }"
      ></text>
    }
    @for (
      extension of iv.closedEndDetails.contractualTermExtensions;
      track extension.$uid;
      let i = $index, c = $count
    ) {
      <number
        class="c1s1"
        [(value)]="extension.duration"
        [settings]="{
          label: c > 1 ? 'Extension #' + (i + 1) : 'Extension',
          placeholder: 'years',
          suffix: ' years',
          tag: 'Contractual Term Expiration Extension Duration #' + (i + 1),
        }"
        [validators]="
          ivStore.contractualTermExpirationExtensionDuration_validators()
        "
        [validation.id]="iv.$uid + '_' + extension.$uid"
      ></number>
      <dropdown
        class="c2s1"
        [(value)]="extension.type"
        [settings]="{
          label: ' ',
          options: ENUMS.ExtensionTypeEnum.toOptions,
          tag: 'Contractual Term Expiration Extension Type #' + (i + 1),
        }"
      ></dropdown>

      @if (i === c - 1) {
        <check
          class="c3s1 justify-center"
          [(value)]="
            iv.closedEndDetails.closedEndKeyTerm
              .hasContractualTermUnlimitedExtensions
          "
          [settings]="{
            label: 'Unlimited Extensions',
            tag: 'Contractual Term Expiration Unlimited Extensions',
          }"
        ></check>
      }

      <div class="c4s1 flex place-self-stretch">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Contractual Term Expiration Extension #' + (i + 1),
            }"
            (click)="ivStore.removeContractualTermExtension(extension)"
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Contractual Term Expiration Extension',
            }"
            (click)="ivStore.addContractualTermExtension()"
          ></btn>
        }
      </div>
    }
  </section-group>
}
