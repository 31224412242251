import { Route } from '@angular/router';
import { PAGE_LAYOUT, VALIDATION_SERVICE_TOKEN } from '@aksia/infrastructure';
import { AuthenticationGuard } from './guards/authentication.guard';
import { InvestmentVehicleResolver } from './resolvers/investment-vehicle.resolver';
import { InvestmentVehicleStore } from './stores/investment-vehicle.store';
import { CompanyStore } from './stores/company.store';
import { InvestmentVehiclePageContent } from './pages/investment-vehicle/investment-vehicle.content.page';
import { InvestmentVehiclePageHeader } from './pages/investment-vehicle/investment-vehicle.header.page';
import { CompanyResolver } from './resolvers/company.resolver';
import { CompanyPageHeader } from './pages/company/company.header.page';
import { CompanyPageContent } from './pages/company/company.content.page';
import { HomePageContent } from './pages/home/home.content.page';
import { LoginPageContent } from './pages/login/login.content.page';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    data: {
      layoutType: PAGE_LAYOUT.Center,
      pageContentComponent: LoginPageContent,
    },
    loadComponent: () => import('@aksia/ui').then((page) => page.PageComponent),
  },
  {
    path: 'home',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        data: {
          layoutType: PAGE_LAYOUT.Top,
          layoutSettings: {
            topArea: { height: 64, hasHandle: false },
          },
          pageContentComponent: HomePageContent,
        },
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageComponent),
      },
      {
        path: '',
        outlet: 'top',
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageToolbarComponent),
      },
    ],
  },

  {
    path: 'investmentvehicle/:entityId',
    /* providers: [
      {
        provide: VALIDATION_SERVICE_TOKEN,
        useExisting: InvestmentVehicleStore,
      },
    ], */
    resolve: [InvestmentVehicleResolver],
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        data: {
          layoutType: PAGE_LAYOUT.TopLeft,
          layoutSettings: {
            topArea: { height: 64, hasHandle: false },
            leftArea: { hasHandle: false },
          },
          pageHeaderComponent: InvestmentVehiclePageHeader,
          pageContentComponent: InvestmentVehiclePageContent,
        },
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageComponent),
      },
      {
        path: '',
        outlet: 'top',
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageToolbarComponent),
      },
      {
        path: '',
        outlet: 'left',
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageNavigationComponent),
      },
    ],
  },
  {
    path: 'managementcompany/:entityId',
    providers: [
      {
        provide: VALIDATION_SERVICE_TOKEN,
        useExisting: CompanyStore,
      },
    ],
    resolve: [CompanyResolver],
    data: { store: CompanyStore },
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        data: {
          layoutType: PAGE_LAYOUT.TopLeft,
          layoutSettings: {
            topArea: { height: 64, hasHandle: false },
            leftArea: { hasHandle: false },
          },
          pageHeaderComponent: CompanyPageHeader,
          pageContentComponent: CompanyPageContent,
          pageContentInput: { viewSettings: { tags: ['host'] } },
        },
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageComponent),
      },
      {
        path: '',
        outlet: 'top',
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageToolbarComponent),
      },
      {
        path: '',
        outlet: 'left',
        loadComponent: () =>
          import('@aksia/ui').then((page) => page.PageNavigationComponent),
      },
    ],
  },
];
