import { inject, Signal } from '@angular/core';
import { pipe, tap, switchMap } from 'rxjs';
import {
  signalStoreFeature,
  type,
  withMethods,
  withHooks,
  patchState,
} from '@ngrx/signals';
import {
  withEntities,
  setAllEntities,
  updateEntity,
  entityConfig,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  IUiSpreadSheetCell,
  IValidationService,
  StateEnum,
  StoreModel,
  UTILS,
} from '@aksia/infrastructure';
import {
  PeriodicStream,
  PeriodicStreamType,
  PERIODIC_STREAMS,
  InvestmentVehicle,
  AUMStreamPoint,
  PublicReturnStreamPoint,
  IPeriodicStream,
  PERIODIC_STREAM_REQUESTS,
  PeriodicStreamRequestOptions,
} from '@aksia/models';
import {
  LoadingService,
  PeriodicStreamService,
  ValidationService,
} from '@aksia/services';
import {
  AUMSourceEnum,
  PublicReturnFeeEnum,
  PublicReturnGeneralClassificationEnum,
  PublicReturnSourceEnum,
  StreamPeriodicityEnum,
  StreamSourceEnum,
} from '@aksia/enums';
import { withStreamValidators } from '../validators/stream.store.validators';

const streamConfig = entityConfig({
  entity: type<PeriodicStream>(),
  collection: 'stream',
  selectId: (stream: PeriodicStream) => stream?.streamType!,
});

export function withStream<EntityType = InvestmentVehicle>() {
  return signalStoreFeature(
    {
      state: type<{
        entityId: number | undefined;
        entityTypeId: number | undefined;
        entity: EntityType | undefined;
      }>(),
      computed: type<{
        streamStartDate: Signal<Date | undefined>;
        streamRequestOptions: Signal<PeriodicStreamRequestOptions>;
      }>(),
      methods: type<{
        registerMethod(method: Function, priority?: number): void;
        finalizeMethod(priority: number): void;
        toStoreModelCollection<T, U>(
          dto: Array<T>,
          model: new (...args: any[]) => U,
          meta?: { tags?: Array<string>; attributes?: Map<string, unknown> },
        ): Array<U>;
        toStoreModel<T, U>(
          dto: T,
          model: new (...args: any[]) => U,
          meta?: { tags?: Array<string>; attributes?: Map<string, unknown> },
        ): U;
        /* validate(
          request:
            | IValidationValidateRequest
            | Array<IValidationValidateRequest>,
        ): void;
        removeValidation(
          request: IValidationRequest | Array<IValidationRequest>,
        ): void;
        modelIsDirty(model: StoreModel): { dto: unknown; isDirty: unknown };
        getGroupErrors(groupId: number): Array<string>; */
      }>(),
    },
    withEntities(streamConfig),
    withStreamValidators(),
    withMethods(
      (
        store,
        loading: LoadingService = inject(LoadingService),
        streams: PeriodicStreamService = inject(PeriodicStreamService),
        validation: IValidationService = inject(ValidationService),
      ) => {
        //#region Common Methods

        const loadStreams = () => {
          let streamRequestOptions = store.streamRequestOptions();
          loadStreamsRx(streamRequestOptions);
        };

        const loadStreamHistory = (streamType: PeriodicStreamType) => {
          loadStreamsRx({
            entityId: store.entityId()!,
            entityTypeId: store.entityTypeId()!,
            streamTypes: [streamType],
            streamRequest: PERIODIC_STREAM_REQUESTS.HISTORICAL,
            periodicity: StreamPeriodicityEnum.Monthly.toString(),
            fillMissing: true,
            responseType: 'update',
          });
        };

        const loadStreamAsOf = (
          asOf: unknown,
          streamTypes?: Array<PeriodicStreamType>,
        ) => {
          streamTypes =
            streamTypes ??
            (PERIODIC_STREAMS.PRIVATE_RETURNS_EXPAND.split(
              ',',
            ) as Array<PeriodicStreamType>)!;
          loadStreamsRx({
            entityId: store.entityId()!,
            entityTypeId: store.entityTypeId()!,
            streamTypes,
            streamRequest: PERIODIC_STREAM_REQUESTS.ASOF,
            periodicity: StreamPeriodicityEnum.Monthly.toString(),
            asOf: asOf as string,
            fillMissing: true,
            responseType: 'update',
          });
        };

        const loadStreamsRx = rxMethod<
          PeriodicStreamRequestOptions | undefined
        >(
          pipe(
            tap((streamRequestOptions) => {
              loading.addLoadRequest('Periodic Streams');
            }),
            switchMap((streamRequestOptions) => {
              return streams.getStreams(streamRequestOptions!).pipe(
                tap({
                  next: (streams: Array<IPeriodicStream>) => {
                    if (streamRequestOptions?.responseType === 'update') {
                      let entities = streamRequestOptions.fillMissing
                        ? fillEntities(streams)
                        : initEntities(streams);
                      entities.forEach((entity) => {
                        let existingEntity =
                          store.streamEntityMap()[entity.streamType!];
                        let filteredPoints = entity.datapoints?.filter(
                          (datapoint) =>
                            !existingEntity?.datapoints?.some(
                              (existDataPoint) =>
                                existDataPoint.asOf === datapoint.asOf,
                            ),
                        );
                        patchState(
                          store,
                          updateEntity(
                            {
                              id: entity.streamType!,
                              changes: {
                                historyIsLoaded:
                                  streamRequestOptions?.streamRequest ===
                                  PERIODIC_STREAM_REQUESTS.HISTORICAL,
                                datapoints: [
                                  ...(existingEntity.datapoints ?? []),
                                  ...(filteredPoints ?? []),
                                ].toSorted((a, b) =>
                                  a.asOf! > b.asOf! ? 1 : -1,
                                ),
                              },
                            },
                            streamConfig,
                          ),
                        );
                      });
                    } else {
                      let entities = streamRequestOptions?.fillMissing
                        ? fillEntities(streams)
                        : initEntities(streams);
                      entities.forEach((entity) => {
                        entity.selectedDataPoint = entity.datapoints?.at(0);
                      });
                      patchState(store, setAllEntities(entities, streamConfig));
                    }
                  },
                  error: (error) => {
                    console.error(`Error loading stream: ${error.message}`);
                  },
                  finalize: () => {
                    loading.setLoadResponse('Periodic Streams');
                    store.finalizeMethod(3);
                  },
                }),
              );
            }),
          ),
        );

        const saveStreams = () => {
          store.streamEntities().forEach((stream) => {
            let { pointErrors, dirtyPoints } = stream.datapoints!.reduce(
              (
                acc: {
                  pointErrors: Array<string>;
                  dirtyPoints: Array<unknown>;
                },
                point,
              ) => {
                if (validation.hasErrors()) {
                  acc.pointErrors = [
                    ...validation.stateSummary(),
                    ...pointErrors,
                  ];
                } else {
                  let { isDirty, dto } = { isDirty: true, dto: undefined }; //store.modelIsDirty(point);
                  if (isDirty) {
                    acc.dirtyPoints.push(dto);
                  }
                }
                return acc;
              },
              { pointErrors: [], dirtyPoints: [] },
            );

            if (pointErrors?.length > 0) {
              console.error(pointErrors);
            }

            if (dirtyPoints?.length > 0) {
              console.log(`Saving ${stream.streamType}...`);
              console.log(dirtyPoints);
            } else {
              console.log(`${stream.streamType} have no changes`);
            }
          });
        };

        const initEntities = (
          streams: Array<IPeriodicStream>,
        ): Array<PeriodicStream> => {
          let streamModels = streams?.map(
            (stream: IPeriodicStream & StoreModel) => {
              let streamModel = store.toStoreModel<
                IPeriodicStream,
                PeriodicStream
              >(stream, PeriodicStream);

              let pointClass = PeriodicStream.getStreamPointClass(
                stream.streamType!,
              );

              streamModel.datapoints = streamModel.datapoints?.map((point) => {
                let newPoint = {
                  ...point,
                  asOf: UTILS.DATE.toLocalDate(point.asOf),
                };
                if (stream.streamType === PERIODIC_STREAMS.PUBLIC_RETURNS) {
                  (newPoint as PublicReturnStreamPoint).estimationAsOf = (
                    newPoint as PublicReturnStreamPoint
                  ).estimationAsOf
                    ? UTILS.DATE.toLocalDate(
                        (newPoint as PublicReturnStreamPoint).estimationAsOf,
                      )
                    : undefined;
                }

                return store.toStoreModel(newPoint, pointClass, {
                  attributes: new Map<string, unknown>([
                    ['periodicStreamUid', streamModel.$uid],
                  ]),
                });
              });
              if (streamModel.streamType === PERIODIC_STREAMS.PUBLIC_RETURNS) {
                streamModel.ytd = streamModel.ytd?.map((point) => {
                  let newPoint = {
                    ...point,
                    asOf: UTILS.DATE.toLocalDate(point.asOf),
                  };

                  return store.toStoreModel(newPoint, pointClass);
                });
              }
              if (
                PERIODIC_STREAMS.AUM_EXPAND.includes(streamModel.streamType!)
              ) {
                initSelectedCurrency(streamModel);
              }
              return streamModel;
            },
          );
          return streamModels;
        };

        const fillEntities = (streams: Array<IPeriodicStream>) => {
          let streamModels = initEntities(streams);
          streamModels.forEach((stream) => {
            let currentYear = new Date().getFullYear();
            let minYear = store.streamStartDate()?.getFullYear() ?? currentYear;
            if (!stream.datapoints) {
              stream.datapoints = [];
            }
            Array.from({ length: currentYear - minYear + 1 }).forEach(
              (_, i) => {
                // Fill missing data points
                Array.from({ length: 12 }).forEach((_, j) => {
                  let year = minYear + i;
                  let month = j;

                  let exists = stream.datapoints?.some(
                    (point) =>
                      point?.asOf?.getFullYear() === year &&
                      point?.asOf?.getMonth() === month,
                  );

                  if (exists) return;

                  let pointClass = PeriodicStream.getStreamPointClass(
                    stream.streamType!,
                  );
                  let newPoint = store.toStoreModel(
                    { asOf: UTILS.DATE.toEndOfMonth(new Date(year, month, 1)) },
                    pointClass,
                  );
                  newPoint.$state = StateEnum.IsNew;

                  let index = i * 12 + j;
                  stream.datapoints?.splice(index, 0, newPoint);
                });

                //Fill missing ytd points
                if (stream.streamType === PERIODIC_STREAMS.PUBLIC_RETURNS) {
                  if (!stream.ytd) {
                    stream.ytd = [];
                  }
                  Array.from({ length: 12 }).forEach((_, j) => {
                    let year = minYear + i;

                    let exists = stream.ytd?.some(
                      (point) => point?.asOf?.getFullYear() === year,
                    );

                    if (exists) return;

                    let pointClass = PeriodicStream.getStreamPointClass(
                      stream.streamType!,
                    );
                    let newPoint = store.toStoreModel(
                      { asOf: UTILS.DATE.toEndOfMonth(new Date(year, 11, 1)) },
                      pointClass,
                    );

                    stream.ytd?.splice(i, 0, newPoint);
                  });
                }
              },
            );
            stream.datapoints = stream.datapoints?.toSorted((a, b) => {
              return a.asOf!.getFullYear() < b.asOf!.getFullYear() ||
                (a.asOf!.getFullYear() === b.asOf!.getFullYear() &&
                  a.asOf!.getMonth() > b.asOf!.getMonth())
                ? 1
                : -1;
            });

            stream.ytd = stream.ytd?.toSorted((a, b) => {
              return a.asOf!.getFullYear() < b.asOf!.getFullYear() ? 1 : -1;
            });
          });
          return streamModels;
        };

        const getStreamYears = (periodicStreamType: PeriodicStreamType) => {
          let currentYear = new Date().getFullYear();
          let minYear = store.streamStartDate()?.getFullYear() ?? currentYear;
          return Array.from(Array(currentYear - minYear + 1).keys()).map((i) =>
            (currentYear - i).toString(),
          );
        };

        /* const update = (partialStream: Partial<PeriodicStream>) => {
          if (!partialStream.streamType) return;
          patchState(
            store,
            updateEntity(
              {
                id: partialStream.streamType,
                changes: {
                  ...(partialStream as Partial<PeriodicStream>),
                  $state: StateEnum.Dirty,
                },
              },
              streamConfig,
            ),
          );
        }; */

        const onStreamPointValueChange = (
          stream: PeriodicStream,
          value: unknown,
        ) => {
          if (PERIODIC_STREAMS.AUM_EXPAND.includes(stream.streamType!)) {
            (stream.selectedDataPoint as AUMStreamPoint)!.currency =
              stream.selectedCurrency!;
          }
          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  datapoints: [...stream.datapoints!],
                },
              },
              streamConfig,
            ),
          );
        };

        const setMarkedStreamPoints = (
          stream: PeriodicStream,
          cells: Array<IUiSpreadSheetCell>,
        ) => {
          let selectedDataPoint = cells?.find(
            (cell) => cell.selected,
          )?.dataItem;
          let selectedAsOf = selectedDataPoint.asOf;

          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  selectedDataPoint,
                  markedDataPoints: cells?.map((cell) => cell.dataItem),
                  selectedAsOf,
                  minAsOf: new Date(
                    selectedAsOf.getFullYear(),
                    selectedAsOf.getMonth(),
                    1,
                  ),
                  maxAsOf: new Date(
                    selectedAsOf.getFullYear(),
                    selectedAsOf.getMonth() + 1,
                    0,
                  ),
                  selectedSource:
                    cells.length === 1 ? cells[0].dataItem.source : undefined,
                  selectedFeeType:
                    cells.length === 1
                      ? (cells[0].dataItem as PublicReturnStreamPoint).feeType
                      : undefined,
                  selectedClassification:
                    cells.length === 1
                      ? (cells[0].dataItem as PublicReturnStreamPoint)
                          .classification
                      : undefined,
                  selectedEstimationAsOf:
                    cells.length === 1
                      ? (cells[0].dataItem as PublicReturnStreamPoint)
                          .estimationAsOf
                      : undefined,
                },
              },
              streamConfig,
            ),
          );
        };

        const setSelectedAsOf = (stream: PeriodicStream, asOf: unknown) => {
          stream.selectedAsOf = asOf as Date;
          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  selectedAsOf: asOf as Date,
                },
              },
              streamConfig,
            ),
          );
        };

        const setSelectedSource = (stream: PeriodicStream, source: unknown) => {
          stream.markedDataPoints
            ?.filter(
              (point) =>
                !(
                  point.$state === StateEnum.Pristine &&
                  UTILS.OBJECT.isNil(point.value)
                ),
            )
            ?.forEach((point) => {
              point.source = source as
                | StreamSourceEnum
                | PublicReturnSourceEnum
                | AUMSourceEnum;
              validation.validate(
                `publicReturns ${UTILS.FORMAT.toDateFormat(point.asOf?.toString(), 'MMM-yyyy')}@${stream.$uid!}_${point.$uid!}`,
                point.value,
              );
            });
          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  selectedSource: source as
                    | StreamSourceEnum
                    | PublicReturnSourceEnum
                    | AUMSourceEnum,
                  datapoints: [...stream.datapoints!],
                },
              },
              streamConfig,
            ),
          );
        };

        //#endregion

        //#region AUM Methods

        const initSelectedCurrency = (stream: PeriodicStream) => {
          stream.selectedCurrency = (
            stream.datapoints?.find(
              (point) => (point as AUMStreamPoint).currency,
            ) as AUMStreamPoint
          )?.currency;
        };

        const setSelectedCurrency = (
          stream: PeriodicStream,
          currency: unknown,
        ) => {
          stream.selectedCurrency = currency as string;
          stream.datapoints?.forEach((point) => {
            (point as AUMStreamPoint).currency = stream.selectedCurrency;
          });
          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  selectedCurrency: currency as string,
                  datapoints: [...stream.datapoints!],
                },
              },
              streamConfig,
            ),
          );
        };

        //#endregion

        //#region Public Returns Methods

        const setSelectedFeeType = (
          stream: PeriodicStream,
          feeType: unknown,
        ) => {
          stream.markedDataPoints
            ?.filter(
              (point: PublicReturnStreamPoint) =>
                !(
                  point.$state === StateEnum.Pristine &&
                  UTILS.OBJECT.isNil(point.value)
                ),
            )
            ?.forEach((point: PublicReturnStreamPoint) => {
              point.feeType = feeType as PublicReturnFeeEnum;

              validation.validate(
                `publicReturns ${UTILS.FORMAT.toDateFormat(point.asOf?.toString(), 'MMM-yyyy')}@${stream.$uid!}_${point.$uid!}`,
                point.value,
              );
            });
          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  selectedFeeType: feeType as PublicReturnFeeEnum,
                  datapoints: [...stream.datapoints!],
                },
              },
              streamConfig,
            ),
          );
        };

        const setSelectedClassification = (
          stream: PeriodicStream,
          classification: unknown,
        ) => {
          stream.markedDataPoints
            ?.filter(
              (point: PublicReturnStreamPoint) =>
                !(
                  point.$state === StateEnum.Pristine &&
                  UTILS.OBJECT.isNil(point.value)
                ),
            )
            ?.forEach((point: PublicReturnStreamPoint) => {
              point.classification =
                classification as PublicReturnGeneralClassificationEnum;

              validation.validate(
                `publicReturns ${UTILS.FORMAT.toDateFormat(point.asOf?.toString(), 'MMM-yyyy')}@${stream.$uid!}_${point.$uid!}`,
                point.value,
              );
            });
          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  selectedClassification:
                    classification as PublicReturnGeneralClassificationEnum,
                  datapoints: [...stream.datapoints!],
                },
              },
              streamConfig,
            ),
          );
        };

        const setSelectedEstimationAsOf = (
          stream: PeriodicStream,
          estimationAsOf: unknown,
        ) => {
          stream.markedDataPoints
            ?.filter(
              (point: PublicReturnStreamPoint) =>
                !(
                  point.$state === StateEnum.Pristine &&
                  UTILS.OBJECT.isNil(point.value)
                ),
            )
            ?.forEach((point: PublicReturnStreamPoint) => {
              point.estimationAsOf = estimationAsOf as Date;

              validation.validate(
                `publicReturns ${UTILS.FORMAT.toDateFormat(point.asOf?.toString(), 'MMM-yyyy')}@${stream.$uid!}_${point.$uid!}`,
                point.value,
              );
            });
          patchState(
            store,
            updateEntity(
              {
                id: stream.streamType!,
                changes: {
                  selectedEstimationAsOf: estimationAsOf as Date,
                },
              },
              streamConfig,
            ),
          );
        };

        //#endregion

        return {
          loadStreams,
          loadStreamHistory,
          loadStreamAsOf,
          saveStreams,
          getStreamYears,
          onStreamPointValueChange,
          setMarkedStreamPoints,
          setSelectedAsOf,
          setSelectedSource,
          setSelectedCurrency,
          setSelectedFeeType,
          setSelectedClassification,
          setSelectedEstimationAsOf,
        };
      },
    ),
    withHooks({
      onInit(store) {
        store.registerMethod(store.loadStreams, 3);
        console.log(
          `%c StreamStore initialized`,
          'background: #222; color: orange;',
          store,
        );
      },
    }),
  );
}
